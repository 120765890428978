<template>
  <ul>
    <li v-for="(item, index) in routes" :key="index">
      <router-link :to="item.href">{{ item.title }}</router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import { Link } from "@/options";
import { RouteLocation } from "vue-router";

interface BreadcrumbsData {
  routes: Link[]
}

export default {
  data(): BreadcrumbsData {
    return {
      routes: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(this: BreadcrumbsData, route: RouteLocation) {
        let data = new Array();
        route.matched.forEach((match) => {
          if (match.hasOwnProperty("meta")) {
            let meta = match.meta;
            if (meta.hasOwnProperty("breadcrumbs")) {
              let breadcrumbs = meta.breadcrumbs as Link[];
              breadcrumbs.forEach((b: any) => {
                data.push(b);
              });
            }
            if (meta.hasOwnProperty("breadcrumbFn")) {
              let breadcrumbFn = meta.breadcrumbFn as CallableFunction;
              breadcrumbFn(route.params).forEach((b: Link) => {
                data.push(b);
              });
            }
          }
          this.routes = data;
        });
      },
    },
  },
  props: [],
};
</script>

<style scoped type="scss">
ul {
  display: block;
  list-style-type: none;
  position: absolute;
  left: 250px;
  top: 40px;
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
}

ul li+li::before {
  content: "/";
  padding: 0 5px;
}

a {
  color: #8a8a8a;
}

a:hover {
  color: #c1c1c1;
  border-color: #8a8a8a;
}
</style>
