const HELP_ROUTES = {
    path: '/help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
        breadcrumbs: [
            { title: "LEGO Universe", href: "/" },
            { title: "Help", href: "/help" }
        ],
        rootClass: ["background-8", "layout-light"],
    },
    children: [
        {
            path: ':pathMatch(.*)*',
            component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        },
    ]
};
export { HELP_ROUTES };