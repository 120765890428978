<template>
  <div class="inline-row" ref="root" :style="sty">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUpdated,
} from "vue";
import { Ref, ref } from '@vue/reactivity';

const MIN_HEIGHT = 100;

export default defineComponent({
  setup() {
    const height: Ref<number | undefined> = ref();
    const root: Ref = ref<HTMLElement>();

    const sty = computed(() => {
      if (height.value) {
        return {
          height: height.value + "px",
        };
      } else {
        return {};
      }
    });

    function checkSize() {
      if (root.value) {
        var h = height.value || MIN_HEIGHT;
        let e = root.value as HTMLElement;
        for (var i = 0; i < e.children.length; i++) {
          const child = e.children[i] as HTMLElement;
          h = Math.max(h, child.offsetHeight);
        }
        height.value = h;
      }
    }

    onMounted(() => nextTick(checkSize));
    onUpdated(() => nextTick(checkSize));

    return { root, sty };
  },
});
</script>
