<template>
  <div id="logosOuter">
    <div id="logos">
      <img src="../assets/footer-logos/pegi-large.png">
      <img src="../assets/footer-logos/havok.png">
      <img src="../assets/footer-logos/gamebryo.png">
      <img src="../assets/footer-logos/wb-games.png">
      <img src="../assets/footer-logos/scaleform.png">
      <img src="../assets/footer-logos/dolby-digital.png">
      <img src="../assets/footer-logos/bink-video.png">
      <img src="../assets/footer-logos/mac.png">
      <img src="../assets/footer-logos/pc-dvd.png">
    </div>
  </div>
</template>

<style scoped type="scss">
  #logosOuter {
    background: transparent url(../assets/footer/footerbg.png) 0 0 repeat; padding: 8px;
  }

  #logos {
    width: 950px;
    text-align: center;
    min-height: 95px;
    line-height: 95px;
    margin: 0 auto;
  }

  #logos img {
    vertical-align: middle;
  }
</style>
