//import { configureCompat } from 'vue';

/*configureCompat({
    MODE: 3,
});*/

import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import BannerModule from '@/components/modules/BannerModule.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import InlineRow from '@/components/inline/InlineRow.vue';
import HelpPopup from '@/components/HelpPopup.vue';
import NewsLink from '@/components/inline/NewsLink.vue';
import NexusLink from '@/components/inline/NexusLink.vue';
import Poll from '@/components/news-network/Poll.vue';
import PollImages from '@/components/news-network/PollImages.vue';
import SlideshowModule from '@/components/modules/SlideshowModule.vue';
import TextModule from '@/components/modules/TextModule.vue';

import router from './router'
import { SearchPlugin } from './plugins/search';

const app = createApp(App).use(router);

app.use(SearchPlugin, {});

app.component('banner-module', BannerModule);
app.component('breadcrumbs', Breadcrumbs);
app.component('inline-row', InlineRow);
app.component('poll', Poll);
app.component('poll-images', PollImages);
app.component('nexus-link', NexusLink);
app.component('news-link', NewsLink);
app.component('slideshow-module', SlideshowModule);
app.component('text-module', TextModule);
app.component('help-popup', HelpPopup);

app.mount('#mount');
