<template>
  <div class="slideshow" style="height: 353px">
    <slide
      class="slide"
      v-for="(slide, index) in slides"
      v-bind="slide"
      :key="slide.id"
      :style="slideStyle(index)"
    ></slide>
    <div class="slideshow-navigation">
      <span>
        <a @click="playPause()" class="slideshow-control" :class="toggleCtrl"></a>
        <b class="vr"></b>
        <a
          v-for="(slide, index) in slides"
          :key="slide.id"
          @click="select(index)"
          :class="selected(index)"
          class="slide-bullet"
        ></a>
      </span>
    </div>
  </div>
</template>

<style scoped>
  .slide-bullet, .slideshow-control {
    cursor: pointer;
  }

  .slideshow {
    overflow: hidden;
    border-radius: 25px;
  }

  .slide {
    height: 350px;
    width: 700px;
    transition-property: left;
    transition-duration: 1s;
    position: absolute;
    /*transition-delay: 1s;*/
  }
</style>

<script>
import Slide from "@/components/Slide.vue";

function makeStyle(id, activeID) {
  const selected = id == activeID;
  const diff = id - activeID;
  return {
    left: (diff * 700) + "px",
    opacity: (-1 <= diff && diff <= 1) ? "1.0" : "0.5",
  };
}

const MS_PER_SLIDE = 7000;

export default {
  props: ["slides"],
  data: function () {
    return {
      index: 0,
      playing: this.slides.length > 1,
    };
  },
  mounted() {
    setTimeout(() => this.next(), MS_PER_SLIDE);
  },
  computed: {
    toggleCtrl() {
      return {
        "slideshow-pause": this.playing,
        "slideshow-play": !this.playing,
      }
    }
  },
  methods: {
    playPause() {
      this.playing = !this.playing;
      this.next();
    },
    next() {
      if (this.playing) {
        this.select((this.index + 1) % this.slides.length);
        setTimeout(() => this.next(), MS_PER_SLIDE);
      }
    },
    selected(index) {
      const c = `slide-${index}`;
      return index == this.index ? ["selected", c] : [c];
    },
    slideStyle(index) {
      return makeStyle(index, this.index);
    },
    select(index) {
      this.index = index;
    },
  },
  components: {
    Slide,
  },
};
</script>