<template>
  <teleport to="body">
    <div id="HelpOverlay" :hidden="!show" :class="clazz">
      <div id="HelpPopup">
        <a @click="close()" id="HelpClose"></a>
        <div id="HelpContent">
          <slot></slot>
        </div>
        <div id="HelpButtons">
          <a @click="close()" class="button small" id="HelpOK"><span>Ok</span></a>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, ref } from 'vue';
export default {
  setup() {
    const show = ref(false);

    const clazz = computed(() => {
      let doc = document.getElementById('app');
      return {
        "layout-dark": doc && doc.classList.contains('layout-dark'),
        "layout-light": doc && doc.classList.contains('layout-light'),
      }
    });

    function close() {
      show.value = false;
    }
    function open() {
      show.value = true;
    }

    return { show, clazz, open, close };
  }
};
</script>

