import { CharacterDict } from "./characters";
import { WBM_150 } from "./wbm";

const CHARACTERS_150 = new CharacterDict(150);
CHARACTERS_150.addChars([
    {
        key: "ikeboy",
        name: "Ikeboy",
    },
    {
        key: "ace15",
        name: "Ace15",
    },
    {
        key: "foxmccloud",
        name: "FoxMcCloud",
    },
    {
        key: "michaelvortex",
        name: "michaelvortex",
        stats: {
            health: 10,
            imagination: 31,
            armor: 6,
        },
        items: {
            faction: "Assembly",
            HeadSlot: 8540,
            TorsoSlot: 8597,
            LeftHandSlot: 8532,
            RightHandSlot: 11432,
            LegSlot: 8642,
            BackSlot: 6854,
            TrinketSlot: 5833,
        }
    },
    {
        key: "enigmaticplastic",
        name: "EnigmaticPlastic",
    },
    {
        key: "pangolin2",
        name: "PANGOLIN2",
        stats: {
            health: 11,
            imagination: 38,
            armor: 16,
        },
        items: {
            faction: "Venture",
            HeadSlot: 13525,
            TorsoSlot: 7378,
            LeftHandSlot: 12638,
            RightHandSlot: 12638,
            LegSlot: 12426,
            BackSlot: 13617,
            TrinketSlot: 16035,
        }
    },
    {
        key: "samuraimaster",
        name: "SamuraiMaster"
    },
    {
        key: "hollis",
        name: "Hollis",
    },
    {
        key: "wafflnator",
        name: "Wafflnator",
        stats: {
            armor: 14,
            health: 7,
            imagination: 34,
        },
        items: {
            faction: 'Assembly',
            HeadSlot: 7364,
            TorsoSlot: 7372,
            LeftHandSlot: 12636,
            RightHandSlot: 12636,
            LegSlot: 7384,
            BackSlot: 7417,
            TrinketSlot: 13575,
        },
    }
]);
CHARACTERS_150.addWBMChars(WBM_150.chars);

export default CHARACTERS_150;