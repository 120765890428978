<template>
  <div id="mainNavigation">
    <router-link to="/" rel="nofollow" id="mainNavSplash" class="small">MASSIVELY MULTIPLAYER<br>ONLINE GAME</router-link>
    <router-link to="/" rel="nofollow" id="mainNavSplashHome">HOME</router-link>
    <ul>

      <li id="MainNav0" class="">
        <router-link to="/the-game">
          GAME INFO<br>
          <img src="../assets/main-navigation/the-game.png" alt="GAME INFO" width="67" height="38">
        </router-link>
      </li>

      <li id="MainNav1" class="">
        <router-link to="/community">
          COMMUNITY<br>
          <img src="../assets/main-navigation/community.png" alt="COMMUNITY" width="67" height="38">
        </router-link>
      </li>

      <li id="MainNav2" class="">
        <router-link to="/media">
          MEDIA<br>
          <img src="../assets/main-navigation/media.png" alt="MEDIA" width="67" height="38">
        </router-link>
      </li>

      <li id="MainNav3" class="">
        <router-link to="/parents">
          PARENTS<br>
          <img src="../assets/main-navigation/parents.png" alt="PARENTS" width="67" height="38">
        </router-link>
      </li>

      <li id="MainNav4" class="">
        <router-link to="/help">
          HELP<br>
          <img src="../assets/main-navigation/help.png" alt="HELP" width="67" height="38">
        </router-link>
      </li>

      <li id="MainNav5" class="">
        <router-link to="/my-account">
          MY ACCOUNT<br>
          <img src="../assets/main-navigation/my-account.png" alt="MY ACCOUNT" width="67" height="38">
        </router-link>
      </li>

    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style scoped type="scss">
  #mainNavigation ul {
    background-image: url(../assets/dark/top-menu-v2.png);
  }

  #mainNavigation li a:hover, #mainNavigation li a:focus,
  #mainNavigation li a:active, #mainNavigation li.sel a,
  #mainNavigation li a.router-link-active, #mainNavigation ul li a.router-link-active {
    background-image: url(../assets/dark/menu-hover-v2.png);
  }

  #mainNavSplashHome {
    color: #fff;
    background-image: url(../assets/dark/top-menu-home-link.png);
  }
</style>

<style scoped type="scss">
#mainNavigation {
  display:block;
  position:relative;
  width:950px;
  height:124px;
  margin-bottom:70px;
  margin-top:10px;
  overflow:visible;
  z-index:555
}
#mainNavSplash {
  position:absolute;
  display:block;
  width:274px;
  height:28px;
  margin-left:-135px;
  left:50%;
  top:0;
  text-align:center;
  color:#DDD;
  padding-top:88px;
  font-weight:700;
  line-height:.9em;
  z-index:999
}
#mainNavSplash.small {
  font-size:75%
}
#mainNavSplashHome {
  display:block;
  position:absolute;
  bottom:-20px;
  left:50%;
  margin-left:-36px;
  z-index:-1;
  width:72px;
  height:33px;
  background-position:top center;
  background-repeat:no-repeat;
  text-align:center;
  font-size:85%;
  line-height:43px
}
#mainNavigation ul {
  background-position:top center;
  background-repeat:no-repeat;
  display:block;
  list-style-type:none;
  position:absolute;
  left:0;
  z-index:888;
  width:950px;
  height:124px;
  margin:0;
  padding:0
}
#mainNavigation li {
  float:left;
  list-style-type:none;
  margin:30px 0 0;
  padding:0
}
#mainNavigation li a {
  display:block;
  width:108px;
  height:32px;
  text-align:center;
  font-size:10px;
  padding-top:57px;
  position:relative;
  font-weight:400;
  font-family:"Arial Rounded MT Bold", Arial, Tahoma;
  background-position:-900px -900px;
  background-repeat:no-repeat
}
#mainNavigation li a img {
  position:absolute;
  top:12px;
  left:50%;
  width:67px;
  height:38px;
  margin-left:-33px
}
#mainNavigation li a:hover,
#mainNavigation li a:focus,
#mainNavigation li a:active {
  background-position:0 2px;
  background-repeat:no-repeat
}
#mainNavigation li a.router-link-active {
  /*background-position:100% 2px;*/
  background-repeat:no-repeat
}
#mainNavigation #MainNav0 {
  margin-left:2px
}
#mainNavigation #MainNav0 a {
  width:105px
}
#mainNavigation #MainNav3 {
  margin-left:302px
}
</style>
