<template>
  <div :class="cssClass" timeout="14000">
    <img :src="img" alt="" class="rounded-slideshow slideshow-background" />
    <div class="video-mask" v-if="video">
      <video
        class="slideshow-video"
        v-bind="videoAttrs"
        loop
        autoplay
        poster=""
      >
        <source :src="video" type="video/mp4" />
      </video>
    </div>
    <span class="slideshow-text">
      <p></p>
      <h1 align="right" v-if="title" v-html="title"></h1>
      <div align="right">
        <h3 class="headline1" v-if="headline1" v-html="headline1"></h3>
        <h2 class="headline2" v-html="headline2"></h2>
        <span class="text1" :style="sty" v-html="text1"></span>
        <router-link v-if="link" :to="link">{{ linkText }}</router-link>
      </div>
      <p></p>
    </span>
  </div>
</template>

<script lang="ts">
import { CSSProperties } from "vue";
interface SlideProps {
  id: number;
  img?: string;
  title?: string;
  headline1?: string;
  headline2?: string;
  text1?: string;
  link?: string;
  linkText?: string;
  video?: string;
  videoPos?: "A" | "B" | "C";
  width?: number;
}
type CSSClasses = { [key: string]: boolean };

const VIDEO_ATTRS = {
  A: {
    width: 303,
    height: 200,
  },
  B: {
    width: 308,
    height: 198,
  },
  C: {
    width: 313,
    height: 174,
  },
};

export default {
  props: [
    "id",
    "img",
    "title",
    "headline1",
    "headline2",
    "text1",
    "link",
    "linkText",
    "video",
    "videoPos",
    "width",
  ],
  setup(props: SlideProps) {
    const id = props.id;
    const cssClass: CSSClasses = {
      "slideshow-slide": true,
    };
    cssClass[`slide-${id}`] = true;
    if (props.videoPos) {
      cssClass[`video-pos-${props.videoPos}`] = true;
    }
    const sty = {
      width: (props.width || 320) + "px",
    };
    const videoAttrs = VIDEO_ATTRS[props.videoPos || "A"];

    return { sty, cssClass, videoAttrs, ...props };
  },
};
</script>

<style scoped lang="scss">
.slideshow-slide {
  img {
    width: 698px;
    height: 350px;
  }

  h1 {
    font-weight: 700;
  }

  h2.headline2 {
    font-size: 38px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  h3.headline1 {
    font-size: 22px;
    color: #999;
  }

  .slideshow-text {
    span.text1 {
      display: block;
      color: #f2f2f2;
    }
  }

  &.video-pos-A .video-mask {
    left: 37px;
    top: 123px;
    width: 286px;
    height: 195px;

    video {
      margin-left: -8px;
    }
  }

  &.video-pos-B .video-mask {
    left: 60px;
    top: 132px;
    width: 308px;
    height: 168px;

    video {
      margin-top: -11px;
    }
  }

  &.video-pos-C .video-mask {
    left: 98px;
    top: 130px;
    width: 313px;
    height: 174px;
  }

  .video-mask {
    position: absolute;
    border-radius: 12px;
    overflow: hidden;
    //background-color: red;
  }
}
</style>
