<template>
    <router-link :to="route">{{title}}</router-link>
</template>

<script lang="ts">
import { STORIES } from '@/stories';
import { RouteLocationRaw } from 'vue-router';

interface NewsLinkThis {
    id: string,
}

export default {
    props: ["id"],
    computed: {
        route(this: NewsLinkThis): RouteLocationRaw {
            return {
                name: "story", params: { id: this.id }
            };
        },
        title(this: NewsLinkThis) {
            const story = STORIES[this.id];
            return story ? story.title : "[missing title]";
        }
    },
}
</script>
