<template>
  <div class="column-row">
    <div
      :style="sty"
      class="column column-large column-background first last"
    >
      <div class="column-content"></div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        img: {
            type: String,
            require: true,
        },
    },
    setup(props) {
        const sty = {
            'background-image': `url(${props.img})`,
            'height': '350px',
        };
        return { sty };
    },
}
</script>