<template>
  <div id="Open" class="poll">
    <span id="Poll">
      <p></p>
      <fieldset>
        <p>{{poll.question}}</p>
        <p v-for="a in poll.answers">
          <input :id="a.id" type="radio" :name="poll.id" :value="a.id">
          <label :for="a.id">{{a.text}}</label>
        </p>
      </fieldset>
      <input type="submit" name="Button_Save" value="Vote!" id="Button_Save" class="submitbutton">
    </span>
    <!--
    <span id="Result1">
      <p>What should Brickkeeper's robo-dog be called?</p>
      <ul>
        <li class="">M.U.T.T. (Motorized Universal Technology Terrier)</li>
        <li class="">
          <img src="http://assets.lego.com/images/universe/newsnetwork/img34x010_Bar_17.jpg" alt="" width="102">
          <span class="">51.1%</span>
        </li>
        <li class="">P.U.P. (Pneumatic Utility Partner)</li>
        <li class="">
          <img src="http://assets.lego.com/images/universe/newsnetwork/img34x010_Bar_17.jpg" alt="" width="53">
          <span class="">26.6%</span>
        </li>
        <li class="">D.O.G. (Digital Operating Gizmo)</li>
        <li class="">
          <img src="http://assets.lego.com/images/universe/newsnetwork/img34x010_Bar_17.jpg" alt="" width="44">
          <span class="">22.3%</span>
        </li>
      </ul>
      <p class=""></p>
    </span>
    -->
    <div class="clear"></div>
  </div>
</template>

<script>
  import polls from '@/stories/polls.ts';
  export default {
    props: ["id"],
    computed: {
      poll() {
        let poll = polls[this.id];
        if (poll) {
          return poll;
        } else {
          console.warning(`Missing poll data for id: ${this.id}`);
        }
      }
    }
  }
</script>

<style lang="scss">
  .poll input {
      float: left;
  }

  .poll input {
      margin-right: 10px;
  }

  .poll label {
    padding: 0 5px 0 20px;
    display: block;
  }

  poll .submitbutton {
    float: left;
    width: 90px;
    height: 32px;
    margin: 0;
    border: 0;
    //background: transparent url(https://web.archive.org/web/20110404011734im_/http://cache.lego.com/upload/contentTemplating/UniverseDictionary/images/1033/pic7202ACFB59512C37013A0AA0B782FF2E.gif) no-repeat left top;
    cursor: pointer;
    cursor: hand;
    padding-bottom: 10px;
    padding-top: 7px;
    //color: #888;
    font-size: 0;
    font-weight: bold;
    font-family: Verdana, Arial, Helvetica;
  }
</style>
