export interface CharacterStats {
    armor: number;
    health: number;
    imagination: number;
}

export interface CharacterItems {
    faction?: 'Assembly' | 'Venture' | 'Sentinels' | 'Paradox';
    HeadSlot?: number,
    TorsoSlot?: number,
    LeftHandSlot?: number,
    RightHandSlot?: number,
    LegSlot?: number,
    BackSlot?: number,
    TrinketSlot?: number,
}

export interface WBMItem {

};

export interface WBMChar extends CharacterItems, CharacterStats {
    name: string;
}

export interface WBMData {
    items: WBMItem[],
    chars: WBMChar[],
}

export interface CharacterInterface {
    key: string,
    name: string;
    stats?: CharacterStats;
    items?: CharacterItems;
}

export class Character {
    uid: number;
    key: string;
    name: string;
    stats?: CharacterStats;
    items?: CharacterItems;

    constructor(uid: number, args: CharacterInterface) {
        this.uid = uid;
        this.key = args.key;
        this.name = args.name;
        this.stats = args.stats;
        this.items = args.items;
    }

    ref(): CharacterKey {
        return { universe: this.uid, key: this.key, name: this.name }
    }
}

export class CharacterDict {
    private inner: Map<string, Character> = new Map();
    uid: number;
    constructor(uid: number) {
        this.uid = uid;
    }

    addChars(chars: CharacterInterface[]) {
        chars.forEach(c => {
            this.inner.set(c.key, new Character(this.uid, c))
        });
    }

    addWBMChars(chars: WBMChar[]) {
        chars.forEach(c => {
            const key = c.name.toLowerCase();
            const e = this.inner.get(key);
            const i: CharacterItems = {
                BackSlot: c.BackSlot,
                HeadSlot: c.HeadSlot,
                LeftHandSlot: c.LeftHandSlot,
                RightHandSlot: c.RightHandSlot,
                LegSlot: c.LegSlot,
                TorsoSlot: c.TorsoSlot,
                TrinketSlot: c.TrinketSlot,
                faction: c.faction,
            };
            const s: CharacterStats = {
                armor: c.armor,
                imagination: c.imagination,
                health: c.health,
            };
            if (e) {
                e.items = Object.assign(e.items || {}, i);
                e.stats = Object.assign(e.stats || {}, s);
            } else {
                this.inner.set(key, new Character(this.uid, {
                    name: c.name,
                    key,
                    items: i,
                    stats: s,
                }));
            }
        })
    }

    get(key: string): Character | undefined {
        return this.inner.get(key);
    }

    forEach(callback: (c: Character, key?: string) => void) {
        this.inner.forEach(callback)
    }
}

export interface CharacterKey {
    universe: number;
    key: string;
    name: string;
}