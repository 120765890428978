import { Component, defineAsyncComponent } from "vue";
import Module404 from '@/components/modules/Module404.vue';
import { Link } from "@/options";
import { RouteLocationRaw } from "vue-router";
import { CATEGORY_CHALLENGES, CATEGORY_CONCEPT_ART, CATEGORY_EVENTS, CATEGORY_GAME, CATEGORY_GENERAL, CATEGORY_MLN, CATEGORY_POLLS, CATEGORY_RELNOTES, CATEGORY_VIDEOS } from "./categories";

function contentComponent(id: String | Number) {
  return defineAsyncComponent({
    loader: () => import(`@/content/S${id}.vue`),
    errorComponent: Module404,
  })
}

function smallNewsImage(name: String) {
  return require(`@/assets/news-network/small/${name}`);
}

function largeNewsImage(name: String) {
  return require(`@/assets/news-network/large/${name}`);
}

function newsImage(name: String) {
  return require(`@/assets/news-network/${name}`);
}

/*"71800": {
  title: "General News",
  href: { name: "category", params: {key: "general-news" }},
  name: "general-news",
},*/

export interface Story {
  lang?: string,
  href?: RouteLocationRaw,
  image?: any, // FIXME: type?
  imageLarge?: any,
  category?: Link,
  title: string,
  intro?: string,
  date?: string,
  author?: string,
  long?: boolean,
  extra?: Component,
  extraLong?: boolean,
  component?: Component,
};

var STORIES: Record<string, Story> = {
  "68373": {
    href: { name: "story", params: {id: 68373}},
    image: smallNewsImage("lego-universe.jpg"),
    title: "BrickWorld 2008",
    intro: "Learn about the BrickWorld event.",
    date: "2008-06-03",
    category: CATEGORY_EVENTS,
    author: "LEGOUniverseMaster",
    component: contentComponent(68373),
  },
  "71799": {
    href: { name: "story", params: {id: 71799}},
    title: "Creating a super-cool LEGO Universe logo",
    intro: "This month we went behind the scenes at LEGO® Universe Command Center to show you what it takes to create a super-cool LEGO Universe logo.",
    date: "2008-05-16",
    author: "LEGOUniverseMaster",
    category: CATEGORY_GENERAL,
  },
  "71802": {
    href: { name: "story", params: {id: 71802}},
    image: smallNewsImage('minifig-secrets-revealed.jpg'),
    title: "Minifig Secrets Revealed!",
    intro: "The citizens of LEGO Universe---those little LEGO people that we call minifigs---are mighty important to LEGO Universe...",
    date: "2008-05-16",
    author: "LEGOUniverseMaster",
    category: CATEGORY_GENERAL,
    component: contentComponent(71802),
    long: true,
  },
  "71807": {
    href: { name: "story", params: {id: 71807}},
    image: smallNewsImage('latest-dish-from-lu-developers.jpg'),
    title: "The latest dish from LEGO Universe's developers",
    intro: "At this very moment the LEGO Universe is being built up from electronic bits and bytes...",
    date: "2008-05-16",
    author: "LEGOUniverseMaster",
    category: CATEGORY_GENERAL,
    component: contentComponent(71807),
    long: true,
  },
  "71808": {
    href: { name: "story", params: {id: 71808}},
    image: smallNewsImage('lu-kids-dev-panel.jpg'),
    //title: "Kicking back with a member of the LEGO Universe Kids' development panel",
    title: "The LEGO Universe Kids' development panel",
    intro: "Read about the LEGO Universe Kids’ development panel which is affecting every aspect of the game’s creation.",
    date: "2008-05-16",
    author: "LEGOUniverseMaster",
    category: CATEGORY_GENERAL,
    component: contentComponent(71808),
    long: true,
  },
};
STORIES['73043'] = {
  href: { name: "story", params: {id: 73043}},
  image: smallNewsImage('look-of-lu.jpg'),
  title: "The Latest on the Look of LEGO Universe",
  intro: "The LEGO Universe News Network recently got together with our art director, Phillip Atencio...",
  date: "2008-05-23",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(73043),
  long: true,
};
STORIES['73044'] = {
  href: { name: "story", params: {id: 73044}},
  image: smallNewsImage('behind-the-scenes-art-director.jpg'),
  title: "Behind the scenes with the LEGO Universe Art Director",
  intro: "In this edition, the LEGO Universe art director leads us from art fiction to his real hopes for the game.",
  date: "2008-05-23",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(73044),
  long: true,
};
STORIES['73045'] = {
  href: { name: "story", params: {id: 73045}},
  image: smallNewsImage('lu-design-details-divulged.jpg'),
  title: "LEGO Universe: design details divulged",
  intro: "For LEGO Universe design director Brian Booker, “work” means maximizing gaming fun...",
  date: "2008-06-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(73045),
  long: true,
};
STORIES['73047'] = {
  href: { name: "story", params: {id: 73047}},
  image: smallNewsImage('behind-the-scenes.jpg'),
  title: "Behind the scenes with the LEGO Universe Design Director",
  intro: "In this edition, LEGO Universe's design director converses about the game's currency and the value of bricks.",
  date: "2008-06-01",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(73047),
  long: true,
};
STORIES['74149'] = {
  href: { name: "story", params: { id: 74149 }},
  image: smallNewsImage('tower-with-shard.jpg'), // correct?
  title: "The Castle Tower Attacked",
  date: "2008-05-16",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CONCEPT_ART,
  component: contentComponent(74149),
  long: false,
};
STORIES['74160'] = {
  href: { name: "story", params: {id: 74160}},
  image: smallNewsImage('lu-the-new-logo.jpg'),
  title: "Marking LEGO Universe: the new logo",
  intro: "The challenge: How to symbolize an online world so huge with one little emblem? \n\n(Pictured: LEGO fan logo submissions)",
  date: "2008-06-11",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(74160),
  long: true,
};
STORIES['74162'] = {
  href: { name: "story", params: {id: 74162}},
  image: smallNewsImage('legouniverse-com.jpg'),
  title: "Lift off of the new LEGOuniverse.com!",
  intro: "Welcome to the all new LEGOuniverse.com! Enjoy connecting with this constantly evolving info hub for LEGO Universe...",
  date: "2008-06-10",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(74162),
};
STORIES['78887'] = {
  href: { name: "story", params: {id: 78887}},
  image: smallNewsImage("prof-brickkeeper-bounces-into-mln.jpg"),
  title: "LEGO Universe Brickkeeper bounces into My LEGO Network!",
  intro: "\"Form some fun with me now on My LEGO Network!\"",
  date: "2008-07-09",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
};
STORIES['80859'] = {
  href: { name: "story", params: {id: 80859}},
  image: smallNewsImage('lu-partners.jpg'),
  title: "See what’s \"LUPs\" in LEGO Universe",
  date: "2008-08-04",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(80859),
  long: true,
};
STORIES['80861'] = {
  href: { name: "story", params: {id: 80861}},
  image: smallNewsImage('lup-event-2008.jpg'),
  title: "2008 LEGO Universe Partners event!",
  date: "2008-08-21",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(80861),
};
STORIES['82861'] = {
  href: { name: "story", params: {id: 82861}},
  image: smallNewsImage('brickkeeper-interview.jpg'),
  title: "A secret interview with the Brickkeeper",
  intro: "The LEGO Universe News Network recently met up with Professor Brickkeeper on a mysterious LEGO planet for this exclusive interview...",
  date: "2008-08-18",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(82861),
  long: true,
};
STORIES['82873'] = {
  href: { name: "story", params: {id: 82873}},
  image: smallNewsImage('create-digital-designs-the-lu-way-1.jpg'),
  title: "Create digital designs the LEGO Universe way: Part one",
  intro: "What could be better than diving into a pile of LEGO bricks and beginning to build?",
  date: "2008-08-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(82873),
};
STORIES['88019'] = {
  href: { name: "story", params: {id: 88019}},
  image: smallNewsImage('lu-logo-coming-to-life.jpg'),
  title: "The LEGO Universe logo is coming to life!",
  intro: "Built to reflect an evolving LEGO Universe, the game’s logo was designed to come alive! But how can you harness the sheer storytelling power of this animated symbol?",
  date: "2008-10-07",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(88019),
  long: true,
};
STORIES['88481'] = {
  href: { name: "story", params: {id: 88481}},
  image: smallNewsImage('kids-development-panel-inspires-2.jpg'),
  title: "Kids’ Development Panel inspires newest building phase!",
  intro: "Imagine if you got the earliest insider looks at LEGO Universe. Now, how would you feel if you also got to lend the game’s developers extra inspiration?",
  date: "2008-10-20",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(88481),
  long: true,
}
STORIES['88803'] = {
  href: { name: "story", params: {id: 88803}},
  image: smallNewsImage('create-digital-designs-the-lu-way-2.jpg'),
  title: "Create digital designs the LEGO Universe way: Part two",
  date: "2008-09-22",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(88803),
  long: true,
};
STORIES['90419'] = {
  href: { name: "story", params: {id: 90419}},
  image: smallNewsImage('lu-newsletter.jpg'),
  title: "Nab the LEGO Universe newsletter!",
  intro: "Have you signed up for the LEGO Universe email newsletter yet?",
  author: "LEGOUniverseMaster",
  date: "2008-11-12",
  category: CATEGORY_GENERAL,
  component: contentComponent(90419),
};
STORIES['91070'] = {
  href: { name: "story", params: {id: 91070}},
  image: smallNewsImage('create-digital-designs-the-lu-way-3.jpg'),
  title: "Create digital designs the LEGO Universe way: Part 3",
  intro: "In this edition, lead LEGO Universe world artist, Nathan Storm, pilots us into a few of the biggest challenges his team faces as they help design LEGO models for the game world!",
  date: "2008-12-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(91070),
  long: true,
};
STORIES['92302'] = {
  href: { name: "story", params: {id: 92302}},
  image: smallNewsImage('happy-holidays.jpg'),
  lang: "en-us",
  title: "Happy holidays!",
  intro: "Here's wishing \"happy holidays\" to you and yours from the entire LEGO Universe team!",
  author: "LEGOUniverseMaster",
  date: "2008-12-17",
  category: CATEGORY_GENERAL,
  component: contentComponent(92302),
};
STORIES['92825'] = {
  href: { name: "story", params: {id: 92825}},
  image: smallNewsImage('lego-fanwelt.jpg'),
  lang: "en-us",
  title: "Friends and fun at LEGO Fanwelt!",
  intro: "The LEGO Universe team recently made a bunch of amazing new friends at LEGO Fanwelt (LEGO Fans' World) in Cologne, Germany!",
  author: "LEGOUniverseMaster",
  date: "2008-12-15",
  category: CATEGORY_EVENTS,
  component: contentComponent(92825),
};
STORIES['93749'] = {
  href: { name: "story", params: {id: 93749}},
  image: smallNewsImage('prof-brickkeepers-new-pet.jpg'),
  lang: "en-us",
  title: "Professor Brickkeeper's new pet!",
  intro: "LEGO Universe fans have spoken: \"Robo-dog\" was by far the most popular type of pet...",
  author: "LEGOUniverseMaster",
  date: "2018-12-16",
  category: CATEGORY_GENERAL,
  component: contentComponent(93749),
};
STORIES['95809'] = {
  href: { name: "story", params: {id: 95809}},
  image: smallNewsImage('prof-brickkeeper-cooks-up-some-fun.jpg'),
  lang: "en-us",
  title: "Brickkeeper cooks up some fun!",
  intro: "Get cozy with Brickkeeper as he talks about a favorite recipe and some new My LEGO Network friends!",
  date: "2009-01-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(95809),
};
STORIES['97330'] = {
  href: { name: "story", params: {id: 97330}},
  image: smallNewsImage('pirate-captain-sets-sail-for-mln.jpg'),
  lang: "en-us",
  title: "Pirate captain sets sail for My LEGO Network!",
  intro: "A new character from LEGO Universe, Captain Jack Knife, will soon drop anchor on My LEGO Network!",
  date: "2009-01-28",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(97330),
};
STORIES['97860'] = {
  href: { name: "story", params: {id: 97860}},
  image: smallNewsImage('mln-ninja.jpg'),
  lang: "en-us",
  title: "Ninja leader slinking toward My LEGO Network!",
  intro: "His name is Numb Chuck, and he’s both cunning and cool-headed...",
  date: "2009-02-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(97860),
};
STORIES['97861'] = {
  href: { name: "story", params: {id: 97861}},
  image: smallNewsImage('mln-unknown.jpg'),
  lang: "en-us",
  title: "Who’s the mysterious new networker?",
  intro: "If you want to be one of the first to meet the new networker, sign up for the newsletter!",
  date: "2009-02-03",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(97861),
};
STORIES['97866'] = {
  href: { name: "story", params: {id: 97866}},
  // picF8F16F776C11252473A03593FB3E64BE.jpg
  image: smallNewsImage('mln-beginners.jpg'),
  lang: "en-us",
  title: "My LEGO Network for beginners",
  intro: "Want to get in on all the fun, but don’t know how? Read on to learn more...",
  date: "2009-03-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_MLN,
  component: contentComponent(97866),
};
STORIES['97867'] = {
  href: { name: "story", params: {id: 97867}},
  image: smallNewsImage('mln-part-2.jpg'),
  lang: "en-us",
  title: "LEGO Universe lights up My LEGO Network!",
  intro: "Join in the fun that LEGO Universe characters are creating now on My LEGO Network!",
  date: "2009-03-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_MLN,
  component: contentComponent(97867),
  long: true,
};
STORIES['98751'] = {
  href: { name: "story", params: {id: 98751}},
  image: smallNewsImage('video-chicken-dance.jpg'),
  lang: "en-us",
  title: "Check out the chicken dance!",
  intro: "Chickens bob their heads, scratch the ground, flap their wings and strut around! Watch this LEGO Universe minifigure rock like a rooster!",
  date: "2009-03-11",
  author: "LEGOUniverseMaster",
  category: CATEGORY_VIDEOS,
  component: contentComponent(98751),
};
STORIES['98752'] = {
  href: { name: "story", params: {id: 98752}},
  image: smallNewsImage('mln-felix.jpg'),
  lang: "en-us",
  title: "Third new networker revealed!",
  intro: "If you hear a fun song in LEGO Universe, it could be coming from this fellow!",
  date: "2009-02-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(98752),
};
STORIES['99193'] = {
  href: { name: "story", params: {id: 99193}},
  //image: smallNewsImage('jpg'),
  lang: "en-us",
  title: "Get rolling to the Creation Lab!",
  date: "2009-02-16",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(99193),
};
STORIES['99194'] = {
  href: { name: "story", params: {id: 99194}},
  // pic1DD6D5390823C48B5A157427BA0CF951.jpg
  image: smallNewsImage('poll-pet.jpg'),
  lang: "en-us",
  title: "What'll we call Brickkeeper's robo-dog?",
  intro: "Cast your vote to name Professor Brickkeeper's robo-dog!",
  date: "2009-03-24",
  author: "LEGOUniverseMaster",
  category: CATEGORY_POLLS,
  component: contentComponent(99194),
};
STORIES['101699'] = {
  href: { name: "story", params: {id: 101699}},
  image: smallNewsImage('lu-launch-update.jpg'),
  lang: "en-us",
  title: "LEGO Universe launch update",
  intro: "The LEGO Group has an exciting vision for our first massively multiplayer online game (MMOG)...",
  date: "2009-02-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(101699),
  long: true,
};
STORIES['103092'] = {
  href: { name: "story", params: {id: 103092}},
  //image: smallNewsImage('.jpg'),
  lang: "en-us",
  title: "My LEGO Network: New characters' pages live!",
  date: "2009-02-26",
  author: "LEGOUniverseMaster",
  category: CATEGORY_MLN,
  component: contentComponent(103092),
  long: true,
};
STORIES['106758'] = {
  href: { name: "story", params: {id: 106758}},
  image: smallNewsImage('mln-pellet-blaster.jpg'),
  lang: "en-us",
  title: "Get a faster pellet blaster!",
  intro: "Loads of folks are already flaunting the new LEGO Universe Electro-pellet Inductor on their My LEGO Network pages! Find out how to get one...",
  date: "2009-03-10",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(106758),
};
STORIES['106760'] = {
  href: { name: "story", params: {id: 106760}},
  image: smallNewsImage('pirates-vs-ninjas.jpg'),
  lang: "en-us",
  title: "Pirates vs. ninjas: Was your creation picked?",
  intro: "A few of you lucky fans will soon have your creations featured here on the LEGO Universe News Network!",
  date: "2009-03-12",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(106760),
};
STORIES['107581'] = {
  href: { name: "story", params: {id: 107581}},
  image: smallNewsImage('create-digital-designs-the-lu-way-4.jpg'),
  lang: "en-us",
  title: "Create digital designs the LEGO Universe way: Part 4",
  intro: "Lead world artist Nathan Storm returns in this edition to illuminate some of the steps his team takes to make lustrous looking electronic models!",
  date: "2009-03-24",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(107581),
  long: true,
};
STORIES['107596'] = {
  href: { name: "story", params: {id: 107596}},
  // pic4C4E2E173510BF5C07BAF84EB8F681F1 (large)
  //image: smallNewsImage('.jpg'),
  lang: "en-us",
  title: "Fans line up for fun at LEGO World!",
  intro: "About 36,000 fans recently flocked to Copenhagen, Denmark for LEGO World! Read on to learn more...",
  date: "2009-03-23",
  author: "LEGOUniverseMaster",
  category: CATEGORY_EVENTS,
  component: contentComponent(107596),
};
STORIES['109069'] = {
  href: { name: "story", params: {id: 109069}},
  image: smallNewsImage('lu-launch-update.jpg'),
  lang: "en-us",
  title: "Game's public premier planned for next year!",
  intro: "LEGO Universe is now scheduled for takeoff in 2010!",
  date: "2009-03-30",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(109069),
};
STORIES['109151'] = {
  href: { name: "story", params: {id: 109151}},
  //image: smallNewsImage('.jpg'),
  lang: "en-us",
  title: "LEGO Club Day 2009!",
  date: "2009-04-23",
  author: "LEGOUniverseMaster",
  category: CATEGORY_EVENTS,
  component: contentComponent(109151),
};
STORIES['109176'] = {
  href: { name: "story", params: {id: 109176}},
  // pic335DAC0767CE61C68F7F26AB849E2441.jpg
  image: smallNewsImage('challenge-city.jpg'),
  lang: "en-us",
  title: "Get gritty building a city!",
  intro: "A new challenge is live in the Creation Lab! Start stacking bricks and building skyscrapers!",
  date: "2009-04-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(109176),
};
STORIES['109177'] = {
  href: { name: "story", params: {id: 109177}},
  // pic762FD731E615C35DB74AA0397F0E111F.jpg (large)
  imageLarge: largeNewsImage('pirates-ninjas.jpg'),
  image: smallNewsImage('pirates-ninjas.jpg'),
  lang: "en-us",
  title: "A parade of your pirate and ninja creations!",
  intro: "Professional LEGO Universe artists review fan submissions to the “Create a watercraft” and “Make a hideout” challenges!",
  date: "2009-04-08",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(109177),
  long: true,
};
STORIES['110943'] = {
  href: { name: "story", params: {id: 110943}},
  image: smallNewsImage('brickkeepers-pet.jpg'),
  lang: "en-us",
  title: "A name for the Professor's pet!",
  intro: "Find out which name the fans picked for the Brickkeeper's critter...",
  date: "2009-04-16",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(110943),
};
STORIES['110944'] = {
  href: { name: "story", params: {id: 110944}},
  image: smallNewsImage('lunn-unknown.jpg'),
  lang: "en-us",
  title: "What does the latest LEGO Universe character do?",
  intro: "Sign up for the LEGO Universe newsletter and be one of the first to see a preview of a brand new character!",
  date: "2009-04-14",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(110944),
};
STORIES['110971'] = {
  href: { name: "story", params: {id: 110971}},
  // pic56F6AE5FD273A754AA9B9D29AEF1D708.jpg
  image: smallNewsImage('poll-critter.jpg'),
  lang: "en-us",
  title: "Pick a critter!",
  intro: "Choose an animal to be animated by LEGO Universe artists!",
  date: "2009-04-21",
  author: "LEGOUniverseMaster",
  category: CATEGORY_POLLS,
  component: contentComponent(110971),
};
STORIES['110972'] = {
  href: { name: "story", params: {id: 110972}},
  image: smallNewsImage('create-digital-designs-the-lu-way-5.jpg'),
  lang: "en-us",
  title: "Create digital designs the LEGO Universe way: Part 5",
  intro: "Lead animator Erik Beyer reveals a bit of the magic that makes the game’s LEGO models come alive...",
  date: "2009-04-21",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(110972),
  long: true,
};
STORIES['110976'] = {
  href: { name: "story", params: {id: 110976}},
  // pic319FA870C9C64B188F1026D34D8C5C42.jpg
  image: smallNewsImage('challenge-grow.jpg'),
  title: "Grow some fun in the Creation Lab!",
  intro: "Dream up a new type of plant to inhabit LEGO Universe!",
  date: "2009-04-23",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(110976),
};
STORIES['114947'] = {
  href: { name: "story", params: {id: 114947}},
  // picBB1137A812B9717A87B34760246193BA.jpg
  image: smallNewsImage('challenge-big-view.jpg'),
  title: "Build a big view for LEGO Club members!",
  intro: "We're looking for your lofty ideas on how to create a cool LEGO Club lookout tower!",
  date: "2009-04-30",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(114947),
};
STORIES['115237'] = {
  href: { name: "story", params: {id: 115237}},
  // picC210FD248352D987DCB1889212C7634E.jpg
  image: smallNewsImage('lu-launch-update.jpg'),
  title: "Creation Lab capers",
  intro: "Imagine if you took the time to build and upload a super cool LEGO creation, and then someone else copied it and took all the credit. Ouch, right?",
  date: "2009-04-29",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(115237),
};
STORIES['116401'] = {
  href: { name: "story", params: {id: 116401}},
  // picC539FDE3D45DC09ACA35F96E05DA5E63.jpg (large)
  image: smallNewsImage('mech-ready.jpg'),
  lang: "en-us",
  title: "Be first to see this mech ready for a rumble!",
  intro: "Sign up for the free LEGO Universe email newsletter and get a collectible animation of this battle-training mech!",
  date: "2009-05-06",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(116401),
  long: true,
};
STORIES['118021'] = {
  href: { name: "story", params: {id: 118021}},
  // pic53D7BF77C60ECF7937ECFD2874080BC7.jpg (large)
  image: smallNewsImage('concept-squid-cannon.jpg'),
  lang: "en-us",
  title: "Squid cannon storyboards",
  intro: "This image was inspired by the great link between seaworthy pirates and giant octopi...",
  date: "2009-05-07",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CONCEPT_ART,
  component: contentComponent(118021),
  long: true,
};
STORIES['118067'] = {
  href: { name: "story", params: {id: 118067}},
  image: smallNewsImage('news-reporter.jpg'),
  lang: "en-us",
  title: "The newest character reports for duty!",
  intro: "We'll soon be hearing more from our new news reporter as he tries to solve some of the mysteries of LEGO Universe!",
  date: "2009-05-12",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(118067),
};
STORIES['118068'] = {
  href: { name: "story", params: {id: 118068}},
  // pic28FC143D97D90773E8A06EE9BF303259.jpg (large)
  // image: smallNewsImage('.jpg'),
  title: "Your stories starring in a special review!",
  intro: "The Creation Lab’s “Start a story” challenge has unleashed a torrent of creative tale telling...",
  date: "2009-05-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(118068),
};
STORIES['118071'] = {
  href: { name: "story", params: {id: 118071}},
  // image: smallNewsImage('.jpg'),
  lang: "en-us",
  title: "Submit your inspiring scoops!",
  date: "2009-05-27",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(118071),
};
STORIES['118093'] = {
  href: { name: "story", params: {id: 118093}},
  // pic162ED8C3A696D204E1E4033A001C94C1.jpg
  image: smallNewsImage('poll-select-a-story.jpg'),
  lang: "en-us",
  title: "Select a story!",
  intro: "Which type of tale takes you in?",
  date: "2009-05-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_POLLS,
  component: contentComponent(118093),
};
STORIES['121617'] = {
  href: { name: "story", params: {id: 121617}},
  // pic0AA13490-3419-49FD-8B34-6D13DD656E6D.jpg
  image: smallNewsImage('event.jpg'),
  title: "Brickworld 2009",
  intro: "Meet the LEGO Universe team at Brickworld and get a free gift!",
  date: "2009-06-03",
  author: "LEGOUniverseMaster",
  category: CATEGORY_EVENTS,
  component: contentComponent(121617),
};
STORIES['123181'] = {
  href: { name: "story", params: {id: 123181}},
  image: smallNewsImage('create-digital-designs-the-lu-way-6.jpg'),
  lang: "en-us",
  title: "Create digital designs the LEGO Universe way: Part 6",
  intro: "Lead animator Erik Beyer returns in this edition to teach us about the benefits of flexibility in bringing virtual LEGO models to life.",
  date: "2009-06-29",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(123181),
  long: true,
};
STORIES['124154'] = {
  href: { name: "story", params: {id: 124154}},
  // picC867A5BCC41974DF20A49C4159BD2A2F.jpg
  image: smallNewsImage('laughing-with-lego-club-members.jpg'),
  title: "Laughing with LEGO Club members!",
  intro: "The LEGO Universe team had a blast building fun and friendships at LEGO Club day, Germany!",
  date: "2009-06-18",
  author: "LEGOUniverseMaster",
  category: CATEGORY_EVENTS,
  component: contentComponent(124154),
};
STORIES['124215'] = {
  href: { name: "story", params: {id: 124215}},
  // picC23A688E764095CD859D1AD2E902AAFF.jpg (large)
  //image: smallNewsImage('.jpg'),
  title: "Get your creation in the game!",
  date: "2009-06-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(124215),
};
STORIES['124555'] = {
  href: { name: "story", params: {id: 124555}},
  image: smallNewsImage('lu-newsletter-2.jpg'),
  lang: "en-us",
  title: "New LEGO Universe newsletter archive!",
  intro: "Feast your eyes on the new LEGO Universe newsletter archive!",
  date: "2009-06-22",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(124555),
};
STORIES['124810'] = {
  href: { name: "story", params: {id: 124810}},
  // pic68B0CC3876DD95078B1D1FEB19656333.jpg
  image: smallNewsImage('poll-kind-of-builder.jpg'),
  title: "What kind of builder are you?",
  intro: "Are you more of an architect, mechanic or mad-scientist?",
  date: "2009-07-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_POLLS,
  component: contentComponent(124810),
};
STORIES['124811'] = {
  href: { name: "story", params: {id: 124811}},
  image: smallNewsImage('21st-century-snot.jpg'),
  lang: "en-us",
  title: "21st century SNOT: Part one",
  intro: "When it comes to LEGO building, SNOT doesn’t drip from your nose! It stands for “Studs Not (only) On Top”...",
  date: "2009-07-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(124811),
};
STORIES['124812'] = {
  href: { name: "story", params: {id: 124812}},
  // picA82A491EA357047EBB8F45B9A99F672B.jpg
  image: smallNewsImage('challenge-snot.jpg'),
  title: "Can you build a SNOT bot?",
  intro: "The Build a SNOT bot challenge is live in the Creation Lab!",
  date: "2009-07-06",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(124812),
};
STORIES['127603'] = {
  href: { name: "story", params: {id: 127603}},
  // pic7D664EB79461824BA4D5F78AFBDCB7FE (large)
  // picBC86AD08EBB8A0B3CEB524CE13D033C5.jpg
  image: smallNewsImage('challenge-creation-lab.jpg'),
  title: "Take off to the Creation Lab!",
  intro: "Inspire everyone by making a fun LEGO Universe vacation destination!",
  date: "2009-07-13",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(127603),
};
STORIES['127604'] = {
  href: { name: "story", params: {id: 127604}},
  image: smallNewsImage('robot-qb.jpg'),
  title: "Robot quick build",
  intro: "...the major inspiration was just to feel out the look of the player experience of performing a quick build in LEGO Universe.",
  date: "2009-09-14",
  category: CATEGORY_CONCEPT_ART,
  author: "LEGOUniverseMaster",
  component: contentComponent(127604),
};
STORIES['127606'] = {
  href: { name: "story", params: {id: 127606}},
  // pic8F81838C9A3627A309A498B64315FB56.jpg
  image: smallNewsImage('build-your-lu-dreams.jpg'),
  title: "Build your LEGO Universe dreams: Part 1",
  intro: "This series shines a light on the many ways you can make models in LEGO Universe!",
  date: "2009-07-31",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(127606),
  long: true,
};
STORIES['127607'] = {
  href: { name: "story", params: {id: 127607}},
  // pic35DEA9BE6D733838A901C5F08D6DED8A.jpg
  image: smallNewsImage('what-is-a-lego-mmog.jpg'),
  title: "What is a LEGO MMOG?",
  intro: "Even if you already know that MMOG stands for “Massively Multiplayer Online Game,” you may not realize what that says about LEGO Universe, the first MMOG for LEGO fans!",
  date: "2009-07-22",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['129539'] = {
  href: { name: "story", params: {id: 129539}},
  // pic2AE2379F-6C69-42C7-A995-D6E7FB4E00E7.jpg
  title: "Get to know our building pros! Part 1",
  intro: "Have you ever wondered who is designing the models that will be in the game?",
  date: "2009-08-11",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(129539),
  long: true,
};
STORIES['131979'] = {
  href: { name: "story", params: {id: 131979}},
  // picB0821CAC04B119676D753F9DEBAE9282.jpg
  image: smallNewsImage('your-news-reports.jpg'),
  title: "Your news reports reviewed!",
  intro: "This just in: You LEGO Universe fans are filling the Creation Lab with inspiring news stories!",
  date: "2009-09-02",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(131979),
  long: true,
};
STORIES['132229'] = {
  href: { name: "story", params: {id: 132229}},
  // pic7FF82469FFE92E81DBEC03CE04856F8F.jpg
  image: smallNewsImage('challenge-submit-monument.jpg'),
  title: "Make your monuments by August 28th!",
  intro: "Build your best monument before the deadline!",
  date: "2009-08-14",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(132229),
};
STORIES['132675'] = {
  href: { name: "story", params: {id: 132675}},
  // pic426C12463E5EF1932C30D9D42569A460.jpg
  image: smallNewsImage('more-graphics-from-the-game.jpg'),
  title: "More graphics from the game!",
  intro: "Check out these first screenshots of the in-game action!",
  date: "2009-08-19",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(132675),
  long: true,
};
STORIES['134387'] = {
  href: { name: "story", params: {id: 134387}},
  // pic39AB36C8BC88D963769E5C4E784F21B9.jpg (large)
  // pic2A459A81614F68C89474471367C43997.jpg
  image: smallNewsImage('challenge-build-more-fun.jpg'),
  title: "Help Brickeeper build more fun!",
  intro: "Head for the Creation Lab to turn your enthusiasm into inspiration!",
  date: "2009-09-04",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(134387),
};
STORIES['135138'] = {
  href: { name: "story", params: {id: 135138}},
  // picDCB90896443EEB82FC924E3A52FB4C97
  imageLarge: newsImage('more-gameplay-graphics.jpg'),
  image: smallNewsImage('more-gameplay-graphics.jpg'),
  title: "More gameplay graphics!",
  intro: "You LEGO Universe fans go nuts for screenshots, so we're delivering more -- starting now!",
  date: "2009-09-18",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(135138),
};
STORIES['135467'] = {
  href: { name: "story", params: {id: 135467}},
  // pic0D044665-CBE4-47AB-972F-74F3F1924008.jpg
  image: smallNewsImage('event.jpg'),
  title: "Great Western LEGO Show 2009",
  intro: "Meet the LEGO Universe team in the UK!",
  date: "2009-09-24",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(135467),
};
STORIES['136632'] = {
  href: { name: "story", params: {id: 136632}},
  // image: smallNewsImage('.jpg'),
  title: "Brickkeeper's looking for LEGO beasts!",
  date: "2009-10-26",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(136632),
};
STORIES['136728'] = { // may be a different id!
  href: { name: "community-content", params: {id: 136728}},
  lang: "en-us",
  title: "Release Notes February 2010",
  intro: "v.0.180.0",
  category: CATEGORY_RELNOTES,
  component: contentComponent(136728),
};
STORIES['137083'] = {
  href: { name: "story", params: {id: 137083}},
  //image: smallNewsImage(''),
  title: "Fans build their dreams in Swindon!",
  date: "2009-10-19",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(137083),
};
STORIES['138718'] = {
  href: { name: "story", params: {id: 138718}},
  // pic9D0BFB0A16E57DC7731A98A7611E00C7.jpg
  // large: pic93773C4B42F85C53112C1EF53C2F9B4E.jpg
  // image: smallNewsImage(''),
  title: "A new look into LEGO Universe building!",
  intro: "Look into this new LEGO Universe visual!",
  date: "2009-11-17",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(138718),
};
STORIES['139108'] = {
  href: { name: "story", params: {id: 139108}},
  // picC4B165E6FF80BEC80C1B2EEA30A153BA.jpg
  //image: smallNewsImage(''),
  title: "Alien LEGO landing on Earth!?",
  intro: "Scientists specializing in deep space exploration have contacted the LEGO Universe News Network with claims of an incredible find!",
  date: "2009-11-12",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(139108),
};
STORIES['139110'] = {
  href: { name: "story", params: {id: 139110}},
  //image: smallNewsImage(''),
  title: "What will you build?",
  date: "2009-11-12",
  category: CATEGORY_POLLS,
  author: "LEGOUniverseMaster",
  component: contentComponent(139110),
};
STORIES['140026'] = {
  href: { name: "story", params: {id: 140026}},
  //image: smallNewsImage(''),
  title: "Great fun at LEGO World!",
  date: "2009-11-12",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(140026),
};
STORIES['141072'] = {
  href: { name: "story", params: {id: 141072}},
  image: smallNewsImage('build-your-lu-dreams-p2.jpg'),
  title: "Build your LEGO Universe dreams: Part 2",
  intro: "Welcome back to this special series on the fun building features players will find in LEGO Universe!",
  date: "2009-11-17",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(141072),
  long: true,
};
STORIES['141328'] = { // may be a different id!
  href: { name: "community-content", params: {id: 141328}},
  lang: "en-us",
  title: "Release Notes March 2010",
  intro: "v.0.180.2 - v.0.181.5",
  category: CATEGORY_RELNOTES,
  component: contentComponent(141328),
};
STORIES['143098'] = {
  href: { name: "story", params: {id: 143098}},
  // picB313A0FDE49CD7CB58A3A4CEEC506D2B.jpg
  image: smallNewsImage('challenge-homebuilders.jpg'),
  title: "Brickkeeper calls for homebuilders!",
  intro: "Where will you live in LEGO Universe?",
  date: "2009-11-27",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['143101'] = {
  href: { name: "story", params: {id: 143101}},
  // picDAA816B0153F3BF43440E9106D488883.jpg
  image: smallNewsImage('monument.jpg'),
  title: "LEGO monuments power imagination!",
  intro: "You LEGO Universe fans are kicking up a cloud of creative inspiration!",
  date: "2009-11-25",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(143101),
  long: true,
}
STORIES['144087'] = {
  href: { name: "story", params: {id: 144087}},
  // large: pic44C4F13597135FA4FBFC0D186BBDCDC2.jpg
  // image: smallNewsImage('.jpg'),
  title: "More mysterious LEGO® pods arriving soon!",
  intro: "Scientists ask for help in retrieving second pod.",
  date: "2009-11-30",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(144087),
  long: true,
};
STORIES['144934'] = {
  href: { name: "story", params: {id: 144934}},
  // pic1EA67A404B348685C812EA951AC0A54A.jpg
  // image: smallNewsImage('.jpg'),
  title: "LEGO building with Mads Purup",
  intro: "What’s special about LEGO Universe Free Building and Modular Building compared to Quick Building?",
  date: "2009-11-27",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(144934),
  long: true,
};
STORIES['147671'] = {
  href: { name: "story", params: {id: 147671}},
  // pic2533C5EC8568D2F13048BD518773735C.jpg
  image: smallNewsImage('pod-2.jpg'),
  title: "Second LEGO® pod lands in Orlando, Florida!",
  intro: "Third pod landing soon, say scientists.",
  date: "2009-12-10",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(147671),
};
STORIES['147678'] = {
  href: { name: "story", params: {id: 147678}},
  // image: smallNewsImage(''),
  title: "Get to know our building pros! Part 2",
  date: "2009-12-08",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(147678),
  long: true,
};
STORIES['148675'] = {
  href: { name: "story", params: {id: 148675}},
  // picA6060EC8F222F24412A7C4D1B2ADD635.jpg
  image: smallNewsImage('pod-3.jpg'),
  title: "Third LEGO® pod lands in Houston, Texas",
  intro: "Most thought it would be Dallas, but Pod 3 shocked everyone...",
  date: "2009-12-15",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(148675),
};
STORIES['148676'] = {
  href: { name: "story", params: {id: 148676}},
  // picA448ACD98777B9D7EC7590254A3E408B.jpg
  image: smallNewsImage('pod-4.jpg'),
  title: "Who will find the fourth LEGO® pod?",
  intro: "Set to land in Chicago any day now, Pod 4 is eagerly awaited.",
  date: "2009-12-16",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(148676),
};
STORIES['148797'] = { // may be a different id!
  href: { name: "community-content", params: {id: 148797}},
  lang: "en-us",
  title: "Release Notes April 2010",
  intro: "v.0.182.0 - v.0.183.8",
  category: CATEGORY_RELNOTES,
  component: contentComponent(148797),
};
STORIES['149369'] = {
  href: { name: "story", params: {id: 149369}},
  // pic32D6992EB19687FD9D1A4461D98F016F.jpg
  image: smallNewsImage('pick-a-way-to-battle-darkness.jpg'),
  title: "Pick a way to battle darkness!",
  intro: "How do you want to battle for the future of LEGO Universe?",
  date: "2010-01-04",
  category: CATEGORY_POLLS,
  author: "LEGOUniverseMaster",
  component: contentComponent(149369),
};
STORIES['149370'] = {
  href: { name: "story", params: {id: 149370}},
  //image: smallNewsImage(''),
  title: "How will you connect with friends?",
  date: "2009-12-23",
  category: CATEGORY_POLLS,
  author: "LEGOUniverseMaster",
  component: contentComponent(149370),
};
STORIES['149946'] = {
  href: { name: "story", params: {id: 149946}},
  // picD1962F77-EC85-4810-8CA5-8450E32EE025.jpg
  // pic4420FBDBD7182FFC238BCF63D6687BFE.jpg
  image: smallNewsImage('mysterious-minifig-message.jpg'),
  title: "Reveal the mysterious minifig message!",
  intro: "Another four letters will need to be found in the data from Pod 7.",
  date: "2010-01-04",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(149946),
};
STORIES['150030'] = {
  href: { name: "story", params: {id: 150030}},
  // pic164CA2165A1226BA25E09D5CE2DF2C62.jpg
  // image: smallNewsImage(''),
  title: "Three LEGO® pods left! Pod 5 to land in Berlin, Germany",
  intro: "\"It is vital that people keep finding these pods!\"",
  date: "2009-12-20",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(150030),
};
STORIES['150524'] = {
  href: { name: "story", params: {id: 150524}},
  // picF16B6216EA4445F4064DD22460010B65.jpg
  image: smallNewsImage('connect-in-lu.jpg'),
  title: "Connect in LEGO Universe!",
  intro: "LEGO Universe will offer worlds of ways for LEGO fans to socialize and connect online!",
  date: "2009-12-23",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(150524),
};
STORIES['150530'] = {
  href: { name: "story", params: {id: 150530}},
  // pic6B0F59D36D2960AAD651EE42D6A3254E.jpg
  image: smallNewsImage('pod-5.jpg'),
  title: "Only two LEGO® pods to go. England - you're next!",
  intro: "After a successful landing for Pod 5 in Berlin, the signal for Pod 6 has centered on England.",
  date: "2009-12-26",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(150530),
};
STORIES['150531'] = {
  href: { name: "story", params: {id: 150531}},
  // pic4B845B16662AD05065172980313662DF.jpg
  image: smallNewsImage('pod-6.jpg'),
  title: "The final LEGO® pod is on its way!",
  intro: "Pod 7, the last of the mysterious LEGO pods, is set to land on Earth somewhere along the US west coast.",
  date: "2009-12-30",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(150531),
};
STORIES['150739'] = {
  href: "/community/news-network/s/150739",
  image: require("@/assets/news-network/lego-universe-small.jpg"),
  title: "LEGO World Zwolle 2009",
  intro: "Come visit LEGO WORLD and enter a world full of fantasy, excitement and activities!",
  date: "2009-10-15",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster"
};
STORIES['150757'] = {
  href: "/community/news-network/s/150757",
  image: require("@/assets/news-network/lego-universe-small.jpg"),
  title: "Great Western LEGO Show 2009",
  intro: "Meet the LEGO Universe team in the UK!",
  date: "2009-09-24",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster"
};
STORIES['150764'] = {
  href: "/community/news-network/s/150764",
  image: require("@/assets/news-network/lego-universe-small.jpg"),
  title: "Brickworld 2009",
  intro: "Meet the LEGO Universe team at Brickworld and get a free gift!",
  date: "2009-06-03",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster"
};
STORIES['150781'] = {
  href: "/community/news-network/s/150781",
  image: require("@/assets/news-network/lego-universe-small.jpg"),
  title: "LEGO FANWELT Deutschland 2008",
  intro: "The LEGO Universe team will be building more fun at this giant LEGO fan event in Germany!",
  date: "2008-10-27",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster"
};
STORIES['150784'] = {
  href: "/community/news-network/s/150784",
  image: require("@/assets/news-network/lego-universe-small.jpg"),
  title: "LEGO WORLD 2008",
  intro: "Learn about the exciting LEGO event taking place in the Netherlands.",
  date: "2008-10-13",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster"
};
STORIES['150787'] = {
  href: "/community/news-network/s/150787",
  image: require("@/assets/news-network/lego-universe-small.jpg"),
  title: "BrickFair 2008",
  intro: "Learn about the DC-based LEGO fan festival.",
  date: "2008-08-18",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster"
};
STORIES['150789'] = Object.assign({}, STORIES['68373']);

STORIES['152405'] = Object.assign({}, STORIES['149946']);
STORIES['152405'].lang = "en-gb";

// picB8446863-1B2D-4BBA-A72A-3E613C8FCB9E.jpg
STORIES['152406'] = Object.assign({}, STORIES['149369']);
STORIES['152406'].lang = "en-gb";

STORIES['154386'] = {
  href: { name: "story", params: {id: 154386}},
  // pic9D77403B1D065C6A023B6AE436004C66.jpg
  image: smallNewsImage('chaos-unleashed.jpg'),
  lang: "en-us",
  title: "Chaos unleashed!",
  intro: "Dark creatures seek to destroy imagination!",
  date: "2010-01-07",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(154386),
};
STORIES['154387'] = {
  href: { name: "story", params: {id: 154387}},
  // pic67C61D0C83747A7020E584EF183FA483.jpg
  image: smallNewsImage('help-brickkeeper-battle-chaos.jpg'),
  lang: "en-us",
  title: "Help Brickkeeper battle chaos!",
  intro: "Will you answer the call to save imagination?",
  date: "2010-01-11",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(154387),
};
STORIES['154458'] = Object.assign({}, STORIES['154386']);
STORIES['154458'].lang = "en-gb";
// picAE5D3B14-1717-4C34-AC37-1B62963DF256.jpg

STORIES['158466'] = {
  // en-us: 158465
  // en-gb: 158466
  href: "/community/news-network/s/158466",
  imageLarge: require("@/assets/news-network/the-great-minifig-mission.jpg"),
  // pic1113D086-1F57-4F50-90E3-F613054C8022.jpg
  image: require("@/assets/news-network/the-great-minifig-mission-small.jpg"),
  title: "The Great Minifig Mission has begun!",
  intro: "Thousands are helping to send minifigs into LEGO Universe to take on the power of The Maelstrom...",
  date: "2010-01-21",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(158465),
};
STORIES['160073'] = {
  href: { name: "story", params: {id: 160073}},
  // pic0C0A6FEF5714921585088FB93D1A25BB.jpg
  image: smallNewsImage('get-into-the-great-minifig-mission.jpg'),
  lang: "en-us",
  title: "Get into the Great Minifig Mission!",
  intro: "How are you helping minifigs enter LEGO Universe?",
  date: "2010-02-09",
  author: "LEGOUniverseMaster",
  category: CATEGORY_POLLS,
  component: contentComponent(160073),
};
STORIES['160285'] = {
  href: { name: "story", params: {id: 160285}},
  image: smallNewsImage("lego-world-copenhagen.jpg"),
  title: "LEGO World Copenhagen 2010",
  intro: "The LEGO Universe team is heading to LEGO World Copenhagen -- enter a world full of fantasy, excitement and activities!",
  date: "2010-02-01",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(160285),
};
STORIES['163386'] = {
  href: { name: "story", params: {id: 163386}},
  // pic122AC20B958FC59E16C21FFEF4AC8FED.jpg
  image: smallNewsImage('safer-internet-day.jpg'),
  lang: "en-us",
  title: "Build with LEGO on Safer Internet Day!",
  intro: "Join in the 2010 Safer Internet Day festivities by overcoming a special challenge in the LEGO Universe Creation Lab...",
  date: "2010-02-08",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(163386),
};
STORIES['166210'] = {
  href: { name: "story", params: {id: 166210}},
  // pic226745BFB9C37644EC1E8A8B035E54C6.jpg
  image: smallNewsImage('build-to-battle-the-maelstrom.jpg'),
  lang: "en-us",
  title: "Build to battle the Maelstrom!",
  intro: "Brickkeeper calls out from the Creation Lab!",
  date: "2010-02-22",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(166210),
};
STORIES['168267'] = {
  href: { name: "story", params: {id: 168267}},
  image: smallNewsImage('great-times-at-lego-world-copenhagen.jpg'),
  title: "Great Times at LEGO World Copenhagen",
  intro: "Over the four days, over 50,000 people visited LEGO World, so the event was a real hit, and the team was certainly kept busy!",
  date: "2010-03-01",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(168267),
  long: true,
};
STORIES['168575'] = {
  href: { name: "story", params: {id: 168575}},
  // pic96553E30F9A87C7D5A435CAC6FA7A919.jpg
  image: smallNewsImage('signup-for-closed-beta.jpg'),
  title: "Sign up for Closed BETA now!",
  intro: "LEGO Universe BETA Testers needed!",
  date: "2010-03-02",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(168575),
};
STORIES['169331'] = {
  href: { name: "story", params: {id: 169331}},
  // pic945714CC65FFEFFC5878CDEA1788EEAF.jpg
  image: smallNewsImage('challenge-more-minifigs.jpg'),
  lang: "en-us",
  title: "Brickkeeper calls for more minifigs!",
  intro: "Chaos surrounds the Creation Lab!",
  date: "2010-03-10",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(169331),
};
STORIES['170558'] = {
  href: { name: "story", params: {id: 170558}},
  // pic9DC69D9C9A89F114C24757A2F3348389.jpg
  image: smallNewsImage('lego-club-battles-chaos.jpg'),
  lang: "en-us",
  title: "LEGO® Club battles chaos!",
  intro: "Brickkeeper sends Club members a decoder!",
  date: "2010-05-02",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(170558),
  long: true,
};
STORIES['170595'] = Object.assign({}, STORIES['170558']);
STORIES['170595'].lang = "en-gb";
STORIES['170595'].date = "2010-06-09";

STORIES['171024'] = {
  href: { name: "story", params: {id: 171024}},
  // picCD900BB279F220A576C8B6F0D97DAA29.jpg
  image: smallNewsImage('poll-beta-tester.jpg'),
  lang: "en-us",
  title: "Are you a LEGO® Universe BETA Tester?",
  intro: "Tell us if you’re in the Closed BETA!",
  date: "2010-05-13",
  author: "LEGOUniverseMaster",
  category: CATEGORY_POLLS,
  component: contentComponent(171024),
};
STORIES['171027'] = {
  href: { name: "story", params: {id: 171027}},
  image: smallNewsImage('maelstorm-slams-minifig-mission.jpg'),
  lang: "en-us",
  title: "Maelstrom slams the Minifig Mission!",
  intro: "Has the dark menace destroyed the Great Minifig Mission?",
  date: "2010-04-13",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(171027),
};

STORIES['171028'] = Object.assign({}, STORIES['171027']);
// picC8C7669B-2790-4C84-A63A-056EE763B156.jpg
STORIES['171028'].lang = "en-gb";

STORIES['171161'] = {
  href: { name: "story", params: {id: 171161}},
  // pic354C38E3BDEF6D1F22CE0EBFBCB692E9.jpg
  image: smallNewsImage('challenge-brickkeeper-racecar.jpg'),
  lang: "en-us",
  title: "Build Brickkeeeper a racecar!",
  intro: "LEGO® Universe Minifigs need hotrods!",
  date: "2010-04-28",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(171161),
};
STORIES['171484'] = {
  href: { name: "story", params: {id: 171484}},
  //image: smallNewsImage('.jpg'),
  lang: "en-us",
  title: "Create your LEGO® Universe social style!",
  date: "2010-04-23",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(171484),
  long: true,
};
STORIES['171485'] = {
  href: { name: "story", params: {id: 171485}},
  // picF98834C55340551C8D81ED15E616163B.jpg
  image: smallNewsImage('challenge-brickkeeper-day.jpg'),
  lang: "en-us",
  title: "Brighten Brickkeeper's day!",
  intro: "Can you create fun for your friends?",
  date: "2010-04-13",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(171485),
};
STORIES['173107'] = {
  href: { name: "story", params: {id: 173107}},
  //image: smallNewsImage('.jpg'),
  lang: "en-us",
  title: "The Nexus Force needs your creativity!",
  date: "2010-04-20",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(173107),
  long: true,
};

STORIES['173110'] = {
  href: { name: "story", params: {id: 173110}},
  // picBFD2D862-A3C9-46C6-A266-77622E7C800A.jpg
  image: smallNewsImage('signup-for-closed-beta.jpg'),
  lang: "en-us",
  title: "Sign up for Closed BETA!",
  intro: "More LEGO Universe BETA Testers needed!",
  date: "2010-04-26",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(173110),
  long: true,
};
STORIES['175133'] = {
  href: { name: "story", params: {id: 175133}},
  image: smallNewsImage('ready-race.jpg'),
  lang: "en-us",
  title: "Ready, set…RACE!",
  intro: "Start your engines for LEGO® Universe auto racing!",
  date: "2010-04-28",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(175133),
  long: true,
};
STORIES['175134'] = {
  href: { name: "story", params: {id: 175134}},
  image: smallNewsImage('join-a-nexus-force-faction.jpg'),
  lang: "en-us",
  title: "Join a Nexus Force faction!",
  intro: "Who do you want to be in LEGO Universe?",
  date: "2010-05-06",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(175134),
};
STORIES['175368'] = {
  href: { name: "story", params: {id: 175368}},
  // pic80780157-E6A6-420D-88DC-836D0001159B.jpg
  image: smallNewsImage('lu-preorder.jpg'),
  lang: "en-us",
  title: "LEGO® Universe pre-ordering starts May 14th!",
  intro: "The wait is nearly over!",
  date: "2010-05-04",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
  component: contentComponent(175368),
};

STORIES['176242'] = Object.assign({}, STORIES['175368']);
STORIES['176242'].lang = "en-gb";

STORIES['176246'] = {
  href: { name: "story", params: {id: 176246}},
  // pic1DABAF1EE0847A474E699AFF495619A8.jpg
  image: smallNewsImage('brickkeeper-needs-new-pets.jpg'),
  lang: "en-us",
  title: "Brickkeeper needs new pets!",
  intro: "Build a LEGO sidekick!",
  date: "2010-05-13",
  author: "LEGOUniverseMaster",
  category: CATEGORY_CHALLENGES,
  component: contentComponent(176246),
};

STORIES['177816'] = {
  href: { name: "story", params: {id: 177816}},
  // pic1EF36184-FDDA-4AA5-8289-1624F169E522.jpg
  image: smallNewsImage('lu-preorder.jpg'),
  title: "LEGO® Universe pre-ordering is open!",
  intro: "Discover how to pre-order the game -- and the benefits!",
  date: "2010-05-14",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(177816),
};
STORIES['179554'] = {
  href: { name: "story", params: {id: 179554}},
  // pic69C9FAB305BC937EBC81A9F45168EF4F.jpg
  image: smallNewsImage('lu-pets.jpg'),
  lang: "en-us",
  title: "Sneak peek at LEGO® Universe pets!",
  intro: "Which pets will you pick for your minifigure?",
  date: "2010-05-19",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GAME,
  component: contentComponent(179554),
  long: true,
};

STORIES['187068'] = { // may be a different id!
  href: { name: "community-content", params: {id: 187068}},
  lang: "en-us",
  title: "Release Notes May 2010",
  intro: "v.0.184.0 - v.0.185.8",
  category: CATEGORY_RELNOTES,
  component: contentComponent(187068),
};
STORIES['187312'] = {
  href: "/community/news-network/s/187312",
  image: require("@/assets/news-network/lu-at-e3-small.jpg"),
  imageLarge: require("@/assets/news-network/lu-at-e3.jpg"),
  title: "LEGO® Universe at E3!",
  intro: "The LEGO® Universe team will be at E3 - the Electronic Entertainment Expo - in Los Angeles this week!",
  date: "2010-06-14",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(187312),
};
STORIES['187314'] = {
  lang: 'en-us',
  title: "Build your LEGO Universe dreams: Part 3",
};
STORIES['187315'] = { // en-us
  href: "/community/news-network/s/187315",
  imageLarge: require("@/assets/news-network/lu-launches-on-10-26-2010.jpg"),
  image: require("@/assets/news-network/lu-launches-on-10-26-2010-small.jpg"),
  title: "LEGO® Universe launch date announced!",
  intro: "The highly anticipated launch of LEGO® Universe is nearly here!",
  date: "2010-06-16",
  category: CATEGORY_GAME,
  author: "LEGOUniverseMaster",
  component: contentComponent(187315),
};
STORIES['187316'] = {
  href: "/community/news-network/s/187316",
  // picE2C725F8A2A9E42FA50EA114AB7B79EE.jpg
  imageLarge: require("@/assets/news-network/brickkeeper-looking-for-summer-fun.jpg"),
  image: require("@/assets/news-network/brickkeeper-looking-for-summer-fun-small.jpg"),
  title: "Brickkeeper's looking for summer fun!",
  intro: "The LEGO Universe News Network has just received this transmission from Professor Brickkeeper!",
  date: "2010-06-22",
  category: CATEGORY_CHALLENGES,
  author: "LEGOUniverseMaster",
  component: contentComponent(187316),
};
STORIES['187324'] = {
  href: "/community/news-network/s/187324",
  // picDAF53EA1BE4C8734C1A6486F9A83814B.jpg
  image: require("@/assets/news-network/signup-for-closed-beta-small.jpg"),
  title: "More Closed-Beta invites on the way!",
  intro: "Got your Closed-Beta Activation Key?",
  date: "2010-06-22",
  category: CATEGORY_GAME,
  author: "LEGOUniverseMaster",
};
STORIES['190618'] = {
  href: "/community/news-network/s/190618",
  image: "", // pic4F372CC7-F950-4259-A422-C03A1D7009D8.jpg
  title: "The all-new LEGOuniverse.com!",
  intro: "Discover what’s new—and what’s on the way!",
  date: "2010-06-09",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['191873'] = {
  href: "/community/news-network/s/191873",
  image: "", // pic6D8DD991-2E2A-4EBC-9472-6BD3CFB63475.jpg
  title: "New attack on the Great Minifig Mission!",
  intro: "The Maelstrom nearly destroys website!",
  date: "2010-06-10",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['195239'] = {
  href: "/community/news-network/s/195239",
  image: require("@/assets/news-network/lu-at-e3-update-small.jpg"),
  title: "LEGO® Universe at E3 - Update from the show floor",
  intro: "We’ve been at E3, the Electronic Entertainment Expo, for two days now, and we’ve been having a blast!",
  date: "2010-06-18",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(195239),
  long: true,
};
STORIES['195837'] = {
  // en-us: 195594
  // en-gb: 195837
  href: "/community/news-network/s/195837",
  // pic1873F91D-0DF8-4CCA-83F4-A51DBCCFAEEA.jpg
  // pic26E26AFD0704133D42B26400EE78D711.jpg
  image: require("@/assets/news-network/e3-awards-rolling-in-for-lu-small.jpg"),
  title: "E3 Awards Rolling in for LEGO® Universe",
  intro: "Last week in Los Angeles, LEGO® Universe took E3 by storm (Maelstrom), capturing the eyes and imaginations of show attendees and members of the press.",
  date: "2010-06-24",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(195837),
};
STORIES['198139'] = {
  // en-us: 198137
  // en-gb: 198139
  href: "/community/news-network/s/198139",
  // picE530A2D8E9C188A543630CD6B0E0765E.jpg
  imageLarge: require("@/assets/news-network/sneak-peek-at-the-lu-story.jpg"),
  // pic45FB6946-6AD1-41B6-AE01-6F07E0E006CD.jpg
  image: require("@/assets/news-network/sneak-peek-at-the-lu-story-small.jpg"),
  title: "Sneak peek at the LEGO® Universe story!",
  intro: "Preview the game's opening video!",
  date: "2010-07-01",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(198137),
};
STORIES['199707'] = {
  href: "/community/news-network/s/199707",
  image: "", // pic3BF30B45-420B-4F29-8A3B-C9FB1B34CA38.jpg
  title: "LEGO Universe awarded 'Best Family Game'!",
  intro: "The E3 awards keep coming, and we are very proud to add another great win to our collection...",
  date: "2010-07-07",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['200089'] = {
  href: "/community/news-network/s/200089",
  image: require("@/assets/news-network/lu-counts-down-to-comic-con-small.jpg"),
  title: "LEGO Universe® Counts Down to Comic-Con",
  intro: "LEGO Universe® is headed to sunny San Diego for Comic-Con International 2010, the largest comic book convention in the world!",
  date: "2010-07-14",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(200089),
};
STORIES['200347'] = {
  href: "/community/news-network/s/200347",
  image: require("@/assets/news-network/new-creatures-coming-to-comic-con-small.jpg"),
  title: "New creatures coming to Comic-Con!",
  intro: "At Comic-Con International 2010, LEGO® Universe will present creatures inspired by YOUR creations!",
  date: "2010-07-22",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(200347),
};
STORIES['200922'] = { // may be a different id!
  href: { name: "community-content", params: {id: 200922}},
  lang: "en-us",
  title: "Release Notes June 2010",
  intro: "v.0.185.9 - v.0.186.5",
  category: CATEGORY_RELNOTES,
  component: contentComponent(200922),
};
STORIES['200928'] = {
  href: "/community/news-network/s/200928",
  image: "", // pic5C0478ED-A190-4F94-86EE-E88552A796B4.jpg"
  title: "Play Mission no. 20!",
  intro: "You can get cool gifts from the Minifigs!",
  date: "2010-07-15",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['208868'] = { // may be a different id!
  href: { name: "community-content", params: {id: 208868}},
  lang: "en-us",
  title: "Release Notes July 2010",
  intro: "v.0.186.6 - v.0.189.3",
  category: CATEGORY_RELNOTES,
  component: contentComponent(208868),
};
STORIES['208881'] = {
  href: "/community/news-network/s/208881",
  image: require("@/assets/news-network/a-fan-centric-comic-con-small.jpg"),
  title: "A fan-centric Comic-Con for LEGO Universe",
  intro: "It’s been a little over a week since we took our Comic-Con fans by storm...",
  date: "2010-08-04",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(208881),
  long: true,
};
STORIES['211742'] = {
  href: "/community/news-network/s/211742",
  image: require("@/assets/news-network/gamescom-small.jpg"),
  imageLarge: require("@/assets/news-network/gamescom.jpg"),
  title: "German version of LEGO® Universe ready to debut at GamesCom",
  intro: "We’re extremely excited to proudly announce that the German version of the game will get its debut at GamesCom in Cologne August 18-22, 2010.",
  date: "2010-08-12",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(211742),
};
STORIES['213807'] = {
  href: "/community/news-network/s/213807",
  // pic5CC66212-95E1-4738-BBAC-22EAE1161B6B.jpg
  image: require("@/assets/news-network/behind-the-scenes-with-lego-fan-small.jpg"),
  title: "Behind the scenes with a true LEGO® fan",
  intro: "Meet Steve Barker, who has been a LUP (LEGO® Universe Partner) since the very early days of LEGO® Universe.",
  date: "2010-09-01",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(213807),
};
STORIES['213809'] = {
  href: "/community/news-network/s/213809",
  //image: "", // pic39FE96FFB3B80BBBA00E7790DA813FDA.jpg
  image: require("@/assets/news-network/gamescom-small.jpg"),
  title: "Successful debut for LEGO® Universe at GamesCom",
  intro: "After months of hard work, we were finally ready and very excited to show off the German version of LEGO® Universe at GamesCom...",
  date: "2010-09-02",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(213809),
  long: true,
};
STORIES['216700'] = {
  href: "/community/news-network/s/216700",
  image: "", // picBEF9C3006C2BAFDF56104A777B10293A.jpg
  title: "LEGO® Universe goes Gold, celebrates at PAX!",
  intro: "While we’re busy getting ready for one of the gaming highlights of the year, we can proudly announce that LEGO Universe has officially gone gold.",
  date: "2010-11-02",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(216700),
};
STORIES['216922'] = { // may be a different id!
  href: { name: "community-content", params: {id: 216922}},
  lang: "en-us",
  title: "Release Notes August 2010",
  intro: "v.0.189.4 - v.0.190.24",
  category: CATEGORY_RELNOTES,
  component: contentComponent(216922),
};
STORIES['218090'] = {
  href: "/community/news-network/s/218090",
  image: "", // picA1E8DD72C6F0E80AD37D9FC18F75A5B8.jpg
  title: "LEGO® Universe took the gamer crowd by storm at PAX",
  intro: "The last huge American industry event of the year turned out to be everything LEGO® Universe had hoped for and more...",
  date: "2010-11-13",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(218090),
  long: true,
};
STORIES['221651'] = {
  href: { name: "community-content", params: {id: 221651}},
  lang: "en-us",
  title: "Release Notes September 2010",
  intro: "v.0.190.25 - v.0.0.35",
  category: CATEGORY_RELNOTES,
  component: contentComponent(221651),
};
STORIES['226028'] = {
  href: "/community/news-network/s/226028",
  // pic69470324453796B01CA33E9E6CEF0182.jpg
  image: require("@/assets/news-network/lu-bringing-creations-to-life-at-brick-con-small.jpg"),
  title: "LEGO® Universe is bringing creations to life at BrickCon",
  intro: "LEGO® Universe is coming to BrickCon",
  date: "2010-11-27",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(226028),
}
STORIES['226072'] = {
  href: { name: "community-content", params: {id: 226072}},
  lang: "en-us",
  title: "Release Notes October 2010",
  intro: "October 22 2010 (v.1.0.8d) | October 21 2010 (1.0.8)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(226072),
};
STORIES['226080'] = {
  href: "/community/news-network/s/226080",
  image: "", // pic85D388E5-7C52-484A-B472-DFE15CE76899.jpg
  title: "LEGO Universe opens its doors for Founders October 12th",
  intro: "Founder’s Release is now just around the corner.",
  date: "2010-09-28",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['226839'] = {
  href: "/community/news-network/s/226839",
  // pic10F410FE46393CB27855529783A02AB9.jpg
  image: require("@/assets/news-network/early-access-even-earlier-small.jpg"),
  title: "Early Access even Earlier",
  intro: "The LEGO® Universe community has been buzzing with anticipation",
  date: "2010-10-06",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
  component: contentComponent(226839),
};
STORIES['228135'] = {
  href: "/community/news-network/s/228135",
  // pic1727D2D9918751D7CE5A4DBB0B40DB9F.jpg
  image: require("@/assets/news-network/lu-brought-creations-to-life-at-brick-con-small.jpg"),
  title: "LEGO® Universe brought creations to life at BrickCon",
  intro: "During the first weekend of October, at the popular LEGO® hobbyist event BrickCon in Seattle, we took the classic old brick into the future.",
  date: "2010-10-14",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(228135),
  long: true,
};
STORIES['228532'] = {
  href: "/community/news-network/s/228532",
  image: "", //picB69971BD-5510-4C07-891F-9F3808ACC9BE.jpg"
  title: "The LEGO® Universe story!",
  intro: "New video sets the stage for your entry into LEGO® Universe!",
  date: "2010-10-15",
  category: CATEGORY_GENERAL,
  author: "LEGOUniverseMaster",
};
STORIES['229903'] = {
  href: "/community/news-network/s/229903",
  // picD6904A88093E8B94E1FE9CBD85145C73.jpg
  image: require("@/assets/news-network/all-good-things-come-in-threes-small.jpg"),
  title: "All good things come in threes",
  intro: "On 2010-10-8 the LEGO® Universe team picked up 3 prestigious game awards at the Frankfurter Buchmesse in Germany!",
  date: "2010-10-20",
  category: CATEGORY_EVENTS,
  author: "LEGOUniverseMaster",
  component: contentComponent(229903),
  long: true,
};
STORIES['232539'] = {
  href: { name: "community-content", params: {id: 232539}},
  lang: "en-us",
  title: "Release Notes November 2010",
  intro: "November 9 2010 (1.1.18)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(232539),
};
STORIES['232954'] = {
  href: "/community/news-network/s/232954",
  image: "", // picE84C0DA4-3DB7-463B-A794-94B90479A158.jpg
  title: "Get LEGO® Universe in time for upcoming holiday content",
  intro: "Find out below where you can purchase your LEGO Universe DVD, Digital Download and Game Card",
  date: "2010-10-12",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
};
STORIES['232972'] = {
  href: "/community/news-network/s/232972",
  image: "", // pic3C002C56-863F-47FD-ADE4-6CF7F29A6B51.jpg
  title: "LEGO® Universe game cards",
  intro: "You will be able to get game cards in most stores from November 16.",
  date: "2010-10-12",
  author: "LEGOUniverseMaster",
  category: CATEGORY_GENERAL,
};
STORIES['248213'] = {
  href: { name: "community-content", params: {id: 248213}},
  lang: "en-us",
  title: "Release Notes December 2010",
  //intro: "February 8 2011 (v.1.4)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(248213),
};
STORIES['258794'] = {
  lang: "en-us",
  title: "[Building Instructions Nimbus Rocket]",
};
STORIES['261632'] = {
  lang: "en-us",
  href: "/community/news-network/s/261632",
  image: require("@/assets/news-network/how-to-build-a-rocket-expert-tips.jpg"),
  title: "How to Build a Rocket: Tips from the Experts!",
  intro: "Duane Hess shares his experience with LEGO Universe rockets!",
  date: "2010-10-28",
  author: "Elementron",
  category: CATEGORY_GENERAL,
};
STORIES['261633'] = Object.assign({}, STORIES['261632']);
STORIES['261633'].lang = "en-gb";

STORIES['262431'] = {
  lang: "en-us",
  title: "[Building Instructions Red Ninja Rocket]",
};

STORIES['262670'] = {
  href: "/community/news-network/s/262670",
  image: "",
  title: "How to Build Rockets: Ninja Dragon Rocket",
  intro: "Bring you Ninja Dragon Rocket to Life!",
  date: "2010-10-21",
  author: "Elementron",
  category: CATEGORY_GENERAL,
};
STORIES['266680'] = {
  lang: "en-us",
  title: "[Building Instructions Classic Rocket]",
};
STORIES['272475'] = {
  lang: "en-us",
  image: "",
  title: "January Build Competition – Rockets!",
  intro: "...",
  date: "2011-01-14",
  category: CATEGORY_GAME,
  author: "Figmentia",
  component: contentComponent(272475),
  extra: () => import("@/content/S272475Legal.vue"),
  long: true,
};
STORIES['272833'] = {
  lang: "en-us",
  href: { name: "community-content", params: {id: 272833}},
  title: "Release Notes January 2011",
  intro: "January 17 2011 (v.1.2.33)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(272833),
};
STORIES['281614'] = {
  href: { name: "community-content", params: {id: 281614}},
  lang: "en-us",
  title: "Release Notes February 2011 (1)",
  intro: "February 8 2011 (v.1.4)",
  category: CATEGORY_RELNOTES,
  component: () => import("@/content/S281614A.vue"),
};
STORIES['281614.5'] = {
  href: { name: "community-content", params: {id: 281614.5}},
  lang: "en-us",
  title: "Release Notes February 2011 (2)",
  intro: "February 23 2011 (v.1.5)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(281614),
};
STORIES['300286'] = {
  href: { name: "community-content", params: {id: 300286}},
  lang: "en-us",
  title: "Release Notes March 2011",
  intro: "22 March 2011 (v.1.5.26)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(300286),
};
STORIES['311430'] = {
  href: { name: "community-content", params: {id: 311430}},
  //image: require('@/assets/news-network/small/nexus-tower.jpg'),
  lang: "en-us",
  title: "Release Notes May 2011",
  intro: "3 May 2011 (v.1.6.50)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(311430),
};
STORIES['311443'] = {
  href: "/community/news-network/s/311443",
  // pic2E25985B2F1A37CEAF516517077EE3AF.jpg
  image: require("@/assets/news-network/community-contest-nexus-jawbox-donation-small.jpg"),
  title: "Community Mission – Nexus Jawbox Donation Contest!",
  intro: "Donate the most bricks towards the construction of Nexus Tower! The Top 20 contributors will receive LEGO Large Brick Boxes!",
  category: CATEGORY_GAME,
  date: "2011-04-27",
  author: "Imaginatrix",
  component: contentComponent(311443),
  extra: () => import("@/content/S311443Legal.vue"),
  long: true,
};
STORIES['312599'] = {
  href: "/community/news-network/s/312599",
  // pic1F698D5B974BB1DE35284084500A357E.jpg
  image: require("@/assets/news-network/dragonmav-chasm-racing-grand-prix-small.jpg"),
  title: "Dragonmaw Chasm Racing Grand Prix!",
  intro: "Have fun racing through the depths of Forbidden Valley the weekend of May 7-8 and win roaring LEGO City prizes!",
  date: "2011-05-04",
  category: CATEGORY_GAME, // ???
  author: "Imaginatrix",
};
STORIES['312867'] = {
  href: { name: "community-content", params: {id: 312867}},
  // not in the original but looks good!
  image: require('@/assets/news-network/small/nexus-tower.jpg'),
  lang: "en-us",
  title: "Release Notes June 2011",
  intro: "1 June 2011 (v 1.7)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(312867),
};
STORIES['313300'] = {
  href: "/community/news-network/s/313300",
  // pic64678C474F941E3A656160F4E64A8197.jpg
  image: "",
  title: "How to Build: Personal Fortress",
  intro: "Learn how to build your very own Personal Fortress, regardless of your Faction!",
  date: "2011-05-09",
  category: CATEGORY_GAME, //???
  author: "Figmentia",
};
STORIES['314417'] = {
  // en-us: 314417
  // en-gb: 314423
  href: "/community/news-network/s/314417",
  // pic48E7391FF3127A016129C704C7572B51.jpg
  image: require("@/assets/news-network/nexus-naomi-presents-first-look-into-nexus-tower-small.jpg"),
  title: "Nexus Naomi presents: The first look into Nexus Tower!",
  intro: "Find out the latest details on what Dr. Overbuild has been up to inside Nexus Tower!",
  date: "2011-05-11",
  category: CATEGORY_GENERAL,
  author: "Figmentia",
  component: contentComponent(314417),
  long: true,
};
STORIES['314552'] = {
  // en-us
  href: "/community/news-network/s/314552",
  // pic38B210A61B73349C4DAF1EE41D113F5D.jpg
  image: require("@/assets/news-network/small/cannon-cove-shooting-gallery.jpg"),
  title: "Cannon Cove Shooting Gallery Showdown",
  intro: "All hands hoay! Take aim at fantastic LEGO City prizes worth over $170!",
  date: "2011-05-12",
  category: CATEGORY_GAME, //???
  author: "Figmentia",
};
STORIES['314632'] = {
  href: "/community/news-network/s/314632",
  // pic63DC5DB3BC10770BA9125F08D5AF15C6.jpg
  image: require("@/assets/news-network/small/dr-overbuilds-nexus-tower-diary.jpg"),
  title: "Dr. Overbuild's Nexus Tower Diary",
  intro: "Diary Entry: Week 1",
  date: "2011-05-13",
  category: CATEGORY_GENERAL,
  author: "Dr. Overbuild",
  component: contentComponent(314632),
  long: true,
};
STORIES['315045'] = {
  href: "/community/news-network/s/315045",
  // pic75ABCDDCA3BD612B96791B6C6FC73FEE.jpg
  image: require("@/assets/news-network/small/nexus-tower-vault.jpg"),
  title: "Vaults and Property Safes are on the way!",
  intro: "When Nexus Tower opens its doors, the new Vault will be available inside the Tower. Additionally, you can build a Safe for your Property!",
  date: "2011-05-16",
  category: CATEGORY_GENERAL,
  author: "Figmentia",
  component: contentComponent(315045),
  long: true,
};
STORIES['315474'] = {
  href: "/community/news-network/s/315474",
  // pic4E3CFD61730F4FB25F54EBA025CEB4B0.jpg
  image: require("@/assets/news-network/small/valiant-weapons.jpg"),
  title: "A closer look at Valiant Weapons and more!",
  intro: "When Nexus Tower is done, Faction Vendors will have Valiant Weapons for sale! Gear Vendor Achilles Plutarch will also have brand new two-handed weapons that you won’t want to miss!",
  date: "2011-05-18",
  category: CATEGORY_GENERAL,
  author: "Figmentia",
  component: contentComponent(315474),
  long: true,
};
STORIES['315965'] = { // en-us
  href: "/community/news-network/s/315965",
  // picCC0784BD9B9D35DDFF21E47D2D2007BB.jpg
  image: require("@/assets/news-network/small/best-faction-screenshot-contest.jpg"),
  title: "Best Faction Screenshot Contest!",
  intro: "So you think your Faction is cool? Show what your Faction is up to, or join forces with other Faction Members, and flaunt your mad Faction skillz!",
  date: "2011-05-19",
  category: CATEGORY_GENERAL,
  author: "Inventrius",
};
STORIES['316343'] = {
  href: "/community/news-network/s/316343",
  // pic3AFDB10A-590D-4AC9-9E91-3A8BF1DDFB10.jpg
  imageLarge: require("@/assets/news-network/dr-overbuilds-nexus-tower-diary.jpg"),
  image: require("@/assets/news-network/small/dr-overbuilds-nexus-tower-diary.jpg"),
  title: "Dr. Overbuild's Nexus Tower Diary",
  intro: "Diary Entry: Week 2",
  date: "2011-05-20",
  category: CATEGORY_GENERAL,
  author: "Dr. Overbuild",
};
STORIES['318012'] = {
  href: "/community/news-network/s/318012",
  title: "Nexus Tower is now open!",
  intro: "...",
  date: "2011-05-25",
  category: CATEGORY_GENERAL,
  author: "Nexus Naomi",
  component: contentComponent(318012),
  long: true,
};
STORIES['334653'] = {
  href: "/community/news-network/s/334653",
  image: require("@/assets/news-network/build-a-race-car-small.jpg"),
  title: "Build a Race Car Contest Winners!",
  intro: "Wondering which grease monkeys built the slickest LEGO Universe rides? Click here to find out!",
  date: "2011-07-05",
  author: "Inventrius",
};
STORIES['334657'] = {
  href: "/community/news-network/s/334657",
  image: require("@/assets/news-network/skeleton-miner-small.jpg"),
  title: "Monster Bio: Skeleton Miner",
  intro: "Paradox scientists have released a report a different kind of Crux Prime invader – the Skeleton Miners of Ninjago!",
  author: "Inventrius",
  date: "2011-07-05",
};
STORIES['334660'] = {
  href: "/community/news-network/s/334660",
  image: require("@/assets/news-network/draw-faction-leader-small.jpg"),
  title: "Draw or Build your Faction Leader Contest Winners!",
  intro: "Excited to see whose Faction Leaders brought home big prizes? Then click this link!",
  author: "Inventrius",
  date: "2011-07-05",
};
STORIES['334961'] = {
  href: "/community/news-network/s/334961",
  image: require("@/assets/news-network/how-to-earn-coins-small.jpg"),
  title: "How to Earn Coins in LEGO Universe: Part One",
  intro: "Click for some helpful money-earning tips!",
  author: "Inventrius",
  date: "2011-07-06",
};
STORIES['334977'] = {
  href: "/community/news-network/s/334977",
  image: require("@/assets/news-network/best-faction-screenshots-small.jpg"),
  title: "Best Faction Screenshot Contest Winners!",
  intro: "Curious as to who won the Best Faction Screenshot contest? Look no further!",
  author: "315723",
  date: "2011-07-05",
};
STORIES['334978'] = {
  href: "/community/news-network/s/334978",
  image: require("@/assets/news-network/fiercest-racecar-screenshot-small.jpg"),
  title: "Fiercest Race Car Screenshot Contest Winners!",
  intro: "Dying to know what extreme screenshots won LEGO prize packs? Click here to find out!",
  author: "315723",
  date: "2011-07-05",
};
STORIES['335037'] = {
  href: "/community/news-network/s/335037",
  image: require('@/assets/news-network/dragonmaw-chasm-grand-prix-small.jpg'),
  title: "Dragonmaw Chasm Grand Prix!",
  intro: "Release your inner speed demon at the Dragonmaw Chasm racetrack this weekend for a chance to win a collection of slick LEGO race car sets!",
  author: "Inventrius",
  date: "2011-07-07",
};
STORIES['335277'] = {
  lang: "en-us",
  href: "/community/news-network/s/335277",
  image: require('@/assets/news-network/small/levels-and-capes.jpg'),
  imageLarge: require('@/assets/news-network/level-up.jpg'),
  title: "LEGO Universe Levels Up!",
  intro: "In the next few weeks a major change will be added to LEGO Universe - Minifigure levels!",
  author: "Figmentia",
  category: CATEGORY_GENERAL,
  date: "2011-07-11",
  component: contentComponent(335277),
  long: true,
};
STORIES['335280'] = Object.assign({}, STORIES['335277']);
STORIES['335280'].lang = "en-gb";

STORIES['335660'] = {
  href: "/community/news-network/s/335660",
  image: require('@/assets/news-network/how-to-earn-coins-small.jpg'),
  imageLarge: require('@/assets/news-network/how-to-earn-coins.jpg'),
  title: "How to Earn Coins in LEGO Universe: Part Two",
  intro: "Click here for more helpful money-earning tips!",
  date: "2011-07-13",
  category: CATEGORY_GENERAL,
  author: "Inventrius",
  component: contentComponent(335660),
  long: true,
};
STORIES['336138'] = {
  href: "/community/news-network/s/336138",
  imageLarge: require('@/assets/news-network/best-adventurous-screenshot-contest.jpg'),
  title: "Best Adventurous Screenshot Contest!",
  intro: "Send us a photo or screenshot of sheer adventurousness in LEGO Universe and you could win a smorgasbord of prizes!",
  date: "2011-07-14",
  category: CATEGORY_GENERAL,
  author: "Inventrius",
};
STORIES['340371'] = {
  href: "/community/news-network/s/340371",
  // pic38B210A61B73349C4DAF1EE41D113F5D.jpg
  image: require("@/assets/news-network/small/cannon-cove-shooting-gallery.jpg"),
  title: "Contest: Cannon Cove Shooting Gallery!",
  intro: "Calm Rusty Steele’s throbbing migraine by quieting down the ruckus in Cannon Cove this weekend!",
  date: "2011-07-27",
  author: "Inventrius",
};
STORIES['341234'] = {
  href: "/community/news-network/s/341234",
  // pic0FAB17C4C8F3223E7FDCEB178D51E622.jpg
  image: require("@/assets/news-network/small/lu-coming-to-brickfair.jpg"),
  title: "LEGO Universe is coming to BrickFair, August 6-7!",
  intro: "Build, race and more with LEGO Universe Team members in person!",
  date: "2011-07-29",
  author: "Figmentia",
};
STORIES['341436'] = {
  href: "/community/news-network/s/341436",
  image: require("@/assets/news-network/small/play-lu-for-free-now.jpg"),
  // pic2EA3DFC6E33D1DA37C7E8AA3A68E42F8.jpg
  imageLarge: require("@/assets/news-network/play-lu-for-free-now.jpg"),
  title: "Play LEGO® Universe for free – now!",
  intro: "You can now download and play LEGO® Universe for free! Start your online adventure now!",
  date: "2011-07-29",
  category: CATEGORY_GENERAL,
  author: "Imaginatrix",
  component: contentComponent(341436),
  long: true,
};
STORIES['341784'] = {
  href: "/community/news-network/s/341784",
  // pic6F68A7F1F976A76F968D57408B743FDF.jpg
  image: require("@/assets/news-network/small/levels-and-capes.jpg"),
  title: "Levels and Capes!",
  intro: "Find out all the details about leveling in LEGO Universe and the introduction of Capes!",
  date: "2011-08-01",
  category: CATEGORY_GENERAL,
  author: "Figmentia",
  component: contentComponent(341784),
  long: true,
};
STORIES['342751'] = {
  lang: "en-us",
  href: "/community/news-network/s/342751",
  // picE9D7CB1F2BC9E50B3CBDA8C2CABCAF43.jpg
  imageLarge: require("@/assets/news-network/spider-queen.jpg"),
  // pic0102B0E623352DDC0A1E5BEA329E5EE3.jpg
  image: require("@/assets/news-network/small/spider-queen.jpg"),
  title: "Take the Challenge of the Spider Queen!",
  intro: "Alert status: Orange!  The Spider Queen has fled Avant Gardens and escaped to Block Yard!",
  date: "2011-08-03",
  author: "Figmentia",
  component: contentComponent(342751),
  long: true,
};
STORIES['342758'] = {
  href: "/community/news-network/s/342758",
  imageLarge: require('@/assets/news-network/power-of-the-nexus-force.jpg'),
  // pic04A986B3556BE545F9AE5734745ED189.jpg
  image: require("@/assets/news-network/small/power-of-the-nexus-force.jpg"),
  title: "Experience the Power of the Nexus Force NOW!",
  intro: "Check out all of the new exciting additions to LEGO Universe!",
  date: "2011-08-03",
  author: "Figmentia",
}
STORIES['342862'] = {
  href: "/community/news-network/s/342862",
  // picF14930A919E31B481949CEE66D533508.jpg
  image: require("@/assets/news-network/small/contest-pet.jpg"),
  title: "August Contest: Create a Pet!",
  intro: "We have a special contest this month – one lucky builder will have his or her unique pet added to LEGO Universe!",
  date: "2011-08-04",
  author: "Imaginatrix",
};
STORIES['343093'] = { // en-us
  href: "/community/news-network/s/343093",
  imageLarge: require('@/assets/news-network/power-of-the-nexus-force.jpg'),
  // pic04A986B3556BE545F9AE5734745ED189.jpg
  image: require("@/assets/news-network/small/power-of-the-nexus-force.jpg"),
  title: "Power of the Nexus Force Release Notes",
  intro: "Including the Official Release Date!",
  date: "2011-08-05",
  author: "Elementron",
};
STORIES['343109'] = { //en-gb
  href: "/community/news-network/s/343109",
  imageLarge: require('@/assets/news-network/power-of-the-nexus-force.jpg'),
  image: require('@/assets/news-network/small/power-of-the-nexus-force.jpg'),
  title: "Power of the Nexus Force Release Notes",
  intro: "Including the Official Release Date!",
  date: "...",
  category: CATEGORY_GENERAL,
  author: "...",
};
STORIES['343111'] = {
  href: { name: "community-content", params: {id: 343111}},
  image: require('@/assets/news-network/small/power-of-the-nexus-force.jpg'),
  lang: "en-us",
  title: "Release Notes August 2011",
  intro: "August 9 Release Notes (v 1.9)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(343111),
};
STORIES['343112'] = Object.assign({}, STORIES['343111']);
STORIES['343112'].lang = "en-gb";

STORIES['343424'] = {
  href: "/community/news-network/s/343424",
  // pic5B68B41CE1E74A888A0A953573295960.jpg
  image: require("@/assets/news-network/small/contest-rocket.jpg"),
  title: "Rocket Contest – Grand Prize Winner Announcement",
  intro: "Find out which fan designed Rocket will be added to LEGO Universe!",
  date: "2011-08-08",
  author: "Imaginatrix",
  component: contentComponent(343424),
  long: true,
};
STORIES['344849'] = {
  href: "/community/news-network/s/344849",
  // pic3D9BC351AA244EDFF86311565F9C1970.jpg
  image: require("@/assets/news-network/small/contest-battle-of-ns.jpg"),
  title: "Battle of Nimbus Station Contest!",
  intro: "The past is in peril! Help Wenn Wuzzit set it right to win classically exclusive in-game items and timeless LEGO Kingdoms sets!",
  date: "2011-08-12",
  author: "Figmentia",
  component: contentComponent(344849),
  long: true,
  extra: () => import("@/content/S344849Legal.vue"),
  extraLong: true,
};
STORIES['348161'] = {
  lang: "en-us",
  href: { name: "story", params: {id: 348161}},
  title: "Trade with peace of mind in LEGO Universe!",
  date: "2011-08-24",
  author: "Figmentia",
  category: CATEGORY_GENERAL,
  component: contentComponent(348161),
  long: true,
};
STORIES['354041'] = {
  href: { name: "community-content", params: {id: 354041}},
  //image: require('@/assets/news-network/small/power-of-the-nexus-force.jpg'),
  lang: "en-us",
  title: "Release Notes September 2011",
  intro: "September 20 Release Notes (v 1.10)",
  category: CATEGORY_RELNOTES,
  component: contentComponent(354041),
};
STORIES['355487'] = {
  href: "/comunity/news-network/s/355487",
  image: "", // ???
  title: "Get Ready for the 2011 Championships!",
  intro: "???",
  date: "2011-10-10",
  category: CATEGORY_GENERAL,
  author: "Figmentia",
  component: contentComponent(355487),
  long: true,
}

export { STORIES };
