import { STORIES } from '@/stories/index';
import { CATEGORIES } from '@/stories/categories';

function storyBreadcrumbs(params: any): any[] {
  let story = STORIES[params.id];
  if (story.category) {
    return [story.category];
  } else {
    return [];
  }
}

function categoryBreadcrumbs(params: any): any[] {
  let category = CATEGORIES[params.key];
  if (category) {
    return [category];
  } else {
    return [];
  }
}

const COMMUNITY_ROUTES = {
    path: '/community/',
    component: () => import(/* webpackChunkName: "community" */ '../views/Community.vue'),
    meta: {
        breadcrumbs: [
            { title: "LEGO Universe", href: "/" },
            { title: "Community", href: "/community" }
        ],
        rootClass: ["background-4", "layout-dark"],
    },
    children: [
        {
            path: '',
            name: 'community',
            component: () => import(/* webpackChunkName: "community-default" */ '../views/community/Default.vue'),
        },
        {
            path: 'release-notes',
            name: 'release-notes-list',
            component: () => import(/* webpackChunkName: "release-notes-list" */ '../views/community/ReleaseNotes.vue'),
        },
        {
            path: 'property-of-the-week/hall-of-fame',
            name: 'community-halloffame',
            component: () => import(/* webpackChunkName: "community-halloffame" */ '../views/community/HallOfFame.vue'),
            meta: {
                breadcrumbs: [
                    { title: "Hall of Fame", href: "/community/property-of-the-week/hall-of-fame" }
                ]
            }
        },
        {
            path: 'property-of-the-week/e/:id',
            name: 'community-property',
            component: () => import(/* webpackChunkName: "community-property" */ '../views/community/PropertyOfTheWeek.vue'),
            meta: {
                breadcrumbsFn: (id: number) => {
                    return [
                        { title: "Property of the Week", href: "/community/property-of-the-week/" }
                    ]
                },
            }
        },
        {
            path: 'content/:id',
            name: 'community-content',
            component: () => import(/* webpackChunkName: "community-content" */ '../views/community/Content.vue'),
            meta: {
                breadcrumbFn: storyBreadcrumbs,
            }
        },
        {
            path: 'news-network',
            component: () => import(/* webpackChunkName: "news-network" */ '../views/community/NewsNetwork.vue'),
            meta: {
                breadcrumbs: [
                    { title: "News Network", href: "/community/news-network" },
                ]
            },
            children: [
                {
                    path: '',
                    name: 'news-network',
                    component: () => import(/* webpackChunkName: "news-network" */ '../views/community/news-network/Default.vue'),
                },
                {
                    path: 'c/:key',
                    name: 'category',
                    component: () => import(/* webpackChunkName: "category" */ '../views/community/news-network/Category.vue'),
                    meta: {
                        breadcrumbFn: categoryBreadcrumbs,
                    },
                },
                {
                    path: 's/:id',
                    name: 'story',
                    component: () => import(/* webpackChunkName: "story" */ '../views/community/news-network/Story.vue'),
                    meta: {
                        breadcrumbFn: storyBreadcrumbs,
                    }
                }
            ]
        },
        {
            path: ':pathMatch(.*)*',
            component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        },
    ],
};

export { COMMUNITY_ROUTES };