import Home from "@/views/Home.vue";
import { RouteRecordRaw } from "vue-router";

const HOME_ROUTES: RouteRecordRaw = {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
        breadcrumbs: [
            { title: "LEGO Universe", href: "/" }
        ],
        rootClass: ["splash-html", "layout-dark"],
    },
};

export { HOME_ROUTES };