import { App } from "vue";
import { Document } from 'flexsearch';

import { Universe, UNIVERSE_DICT } from "@/data/universe";
import { CharacterKey } from "@/data/characters";
import { RouteParamsRaw, Router, useRouter } from "vue-router";

export interface SearchOptions {

}

export interface CharLookup {
    id: number,
    name: string,
}

export class CharacterSearch {
    doc: Document<CharLookup>;
    refs: CharacterKey[];

    constructor(u: Universe) {
        this.doc = new Document({
            tokenize: "full",
            document: {
                id: "id",
                index: ["name"]
            }
        });
        this.refs = [];

        u.characters.forEach(c => {
            let r = c.ref();
            const id = this.refs.length;
            this.doc.add({ id, name: r.name });
            this.refs.push(r);
            //console.log(r);
        });
    }
}

export class Search {
    private _chars?: Map<number, CharacterSearch>;

    constructor() { }

    get characters() {
        if (!this._chars) {
            this._chars = UNIVERSE_DICT.map((u) => new CharacterSearch(u));
        }
        return this._chars;
    }
}

export class SearchRouter {
    router: Router;
    constructor(router: Router) {
        this.router = router;
    }
    goSearch(
        kind: "character" | "item" | "leaderboard",
        params: RouteParamsRaw
    ) {
        this.router.push({ name: `nexus-hq-${kind}-search`, params });
    }
}

export function useSearchRouter(): SearchRouter {
    const router = useRouter();
    return new SearchRouter(router);
}

export const SearchPlugin = {
    install: (app: App, options: SearchOptions) => {
        app.provide('search', new Search());
    }
}