import { Character, CharacterDict, CharacterInterface, CharacterKey } from "./characters";
import CHARACTERS_150 from "./characters-150";
import { WBM_200 } from "./wbm";

export type LangID = "en-us" | "de-de" | "en-gb";
export type CharDict = { [key: string]: CharacterInterface };
export interface UniverseInterface {
    id: number;
    lang: LangID;
    name: string;
    characters: CharacterDict;
};

export class Universe implements UniverseInterface {
    id: number;
    lang: LangID;
    name: string;
    characters: CharacterDict;
    constructor(args: UniverseInterface) {
        this.id = args.id;
        this.lang = args.lang;
        this.name = args.name;
        this.characters = args.characters;
    }

    getChar(key: string): CharacterInterface | undefined {
        return this.characters.get(key);
    }
}

class UniverseDict {
    private inner: Map<number, Universe>;
    constructor(args: UniverseInterface[]) {
        this.inner = new Map();
        args.forEach(x => this.inner.set(x.id, new Universe(x)));
    }

    values(): Universe[] {
        return Array(...this.inner.values())
    }

    get(u: number): Universe {
        const r = this.inner.get(u);
        if (r) {
            return r;
        } else {
            throw Error(`Unknown universe ${u}`);
        }
    }

    forEach(callback: (u: Universe, uid: number) => void) {
        this.inner.forEach(callback);
    }

    map<V>(callback: (u: Universe, uid: number) => V): Map<number, V> {
        let map: Map<number, V> = new Map();
        this.forEach((u, uid) => map.set(uid, callback(u, uid)));
        return map;
    }
}

const CHARACTERS_200 = new CharacterDict(200);
CHARACTERS_200.addWBMChars(WBM_200.chars);

export const UNIVERSE_DICT = new UniverseDict([
    {
        id: 150,
        name: "Overbuild Universe (US)",
        lang: "en-us",
        characters: CHARACTERS_150,
    },
    {
        id: 200,
        name: "Exeter Universum (DE)",
        lang: "de-de",
        characters: CHARACTERS_200,
    },
    {
        id: 201,
        name: "Storm Universe (UK)",
        lang: "en-gb",
        characters: new CharacterDict(201),
    },
    {
        id: 300,
        name: "Darkflame Universe (EU)",
        lang: "en-us",
        characters: new CharacterDict(300),
    }
]);

export function getChar(universe: number, key: string): CharacterInterface | undefined {
    return UNIVERSE_DICT.get(universe).getChar(key);
}

export function getCharKeys(uid: number): CharacterKey[] {
    const data = UNIVERSE_DICT.get(uid);
    return Object.entries(data.characters).map(([key, v]) => { return { universe: uid, key, name: v.name } });
}

export function getUser(uid: number, key: string): CharacterKey {
    const c = UNIVERSE_DICT.get(uid).getChar(key);
    if (c) return { universe: uid, key, name: c.name };
    else throw new Error("Missing user");
}