import { WBMData } from "./characters";

export const WBM_200: WBMData = {
    items: [],
    chars: [
        {
            name: "joebricksy",
            faction: "Paradox",
            health: 10,
            imagination: 36,
            armor: 13,
            HeadSlot: 12786,
            TorsoSlot: 16635,
            LeftHandSlot: 12657,
            RightHandSlot: 12657,
            LegSlot: 16636,
            BackSlot: 14812,
            TrinketSlot: 12735
        },
        {
            name: "joebricksy",
            faction: "Paradox",
            health: 10,
            imagination: 36,
            armor: 13,
            HeadSlot: 12786,
            TorsoSlot: 16635,
            LeftHandSlot: 12657,
            RightHandSlot: 12657,
            LegSlot: 16636,
            BackSlot: 14812,
            TrinketSlot: 12735
        },
        {
            name: "Klies",
            faction: "Assembly",
            health: 10,
            imagination: 42,
            armor: 21,
            HeadSlot: 12786,
            TorsoSlot: 12690,
            LeftHandSlot: 12817,
            RightHandSlot: 12817,
            LegSlot: 12426,
            BackSlot: 12095,
            TrinketSlot: 12735
        },
        {
            name: "noah",
            health: 4,
            imagination: 7,
            armor: 2,
            HeadSlot: 8035,
            TorsoSlot: 8597,
            RightHandSlot: 4880,
            LegSlot: 2527,
        },
        {
            name: "noah",
            health: 4,
            imagination: 7,
            armor: 2,
            HeadSlot: 8035,
            TorsoSlot: 8597,
            RightHandSlot: 4880,
            LegSlot: 2527,
        },
    ]
};

export const WBM_150: WBMData = {
    items: [

    ],
    chars: [
        {
            name: "7op",
            faction: "Paradox",
            health: 11,
            imagination: 38,
            armor: 14,
            HeadSlot: 12983,
            TorsoSlot: 12958,
            LeftHandSlot: 12816,
            RightHandSlot: 12816,
            LegSlot: 12959,
            BackSlot: 12989,
            TrinketSlot: 7989
        },
        {
            name: "alex",
            faction: "Assembly",
            health: 5,
            imagination: 23,
            armor: 3,
            HeadSlot: 7458,
            TorsoSlot: 7481,
            LeftHandSlot: 7556,
            RightHandSlot: 7531,
            LegSlot: 7505,
            BackSlot: 7555,
        },
        {
            name: "Andrew",
            health: 4,
            imagination: 6,
            armor: 0,
            TorsoSlot: 5769,
            LegSlot: 2517,
        },
        {
            name: "Andrew",
            health: 4,
            imagination: 6,
            armor: 0,
            TorsoSlot: 5769,
            LegSlot: 2517,
        },
        {
            name: "asemblest",
            faction: "Assembly",
            health: 5,
            imagination: 20,
            armor: 4,
            HeadSlot: 7458,
            TorsoSlot: 7482,
            LeftHandSlot: 7556,
            RightHandSlot: 7530,
            LegSlot: 7506,
        },
        {
            name: "AstroCheesyMonster",
            faction: "Sentinels",
            health: 9,
            imagination: 33,
            armor: 20,
            HeadSlot: 12976,
            TorsoSlot: 12952,
            LeftHandSlot: 12642,
            RightHandSlot: 12642,
            LegSlot: 12953,
            BackSlot: 12992,
            TrinketSlot: 13574
        },
        {
            name: "AstroCheesyMonster",
            faction: "Sentinels",
            health: 10,
            imagination: 34,
            armor: 18,
            HeadSlot: 7447,
            TorsoSlot: 7471,
            LeftHandSlot: 12642,
            RightHandSlot: 12642,
            LegSlot: 7495,
            BackSlot: 9856,
            TrinketSlot: 9623
        },
        {
            name: "Astrojj",
            faction: "Assembly",
            health: 10,
            imagination: 37,
            armor: 13,
            HeadSlot: 12962,
            TorsoSlot: 12947,
            LeftHandSlot: 12965,
            RightHandSlot: 12964,
            LegSlot: 12949,
            BackSlot: 12963,
            TrinketSlot: 2989
        },
        {
            name: "BraveArmoredBolt",
            faction: "Paradox",
            health: 5,
            imagination: 26,
            armor: 12,
            HeadSlot: 1889,
            TorsoSlot: 7592,
            LeftHandSlot: 12672,
            RightHandSlot: 12731,
            LegSlot: 8640,
            BackSlot: 1889,
            TrinketSlot: 8367
        },
        {
            name: "Calvin",
            health: 4,
            imagination: 6,
            armor: 0,
            HeadSlot: 6200,
            TorsoSlot: 6229,
            RightHandSlot: 4883,
            LegSlot: 2511,
        },
        {
            name: "CheeseyClearPrank",
            faction: "Assembly",
            health: 9,
            imagination: 27,
            armor: 3,
            HeadSlot: 8685,
            TorsoSlot: 13527,
            LeftHandSlot: 2634,
            LegSlot: 2519,
            BackSlot: 8684,
            TrinketSlot: 12735
        },
        {
            name: "chris",
            faction: "Paradox",
            health: 9,
            imagination: 27,
            armor: 8,
            HeadSlot: 7450,
            TorsoSlot: 7474,
            RightHandSlot: 7078,
            LegSlot: 7498,
            BackSlot: 7540,
            TrinketSlot: 2989
        },
        {
            name: "CleverNiftyDude",
            faction: "Venture",
            health: 10,
            imagination: 26,
            armor: 4,
            HeadSlot: 5995,
            TorsoSlot: 8617,
            LeftHandSlot: 12659,
            RightHandSlot: 12659,
            LegSlot: 2516,
            BackSlot: 14805,
            TrinketSlot: 12735
        },
        {
            name: "CORY",
            health: 5,
            imagination: 7,
            armor: 2,
            HeadSlot: 7781,
            TorsoSlot: 4262,
            LeftHandSlot: 11272,
            RightHandSlot: 10433,
            LegSlot: 6202,
        },
        {
            name: "cricket62",
            faction: "Venture",
            health: 18,
            imagination: 33,
            armor: 5,
            HeadSlot: 12781,
            TorsoSlot: 12686,
            LeftHandSlot: 12678,
            RightHandSlot: 12802,
            LegSlot: 13000,
            TrinketSlot: 9626
        },
        {
            name: "Cyril",
            health: 4,
            imagination: 0,
            armor: 0,
            TorsoSlot: 4421,
            LegSlot: 2524,
        },
        {
            name: "DarkflameNinja",
            faction: "Paradox",
            health: 9,
            imagination: 27,
            armor: 9,
            HeadSlot: 6925,
            TorsoSlot: 6929,
            LeftHandSlot: 6924,
            RightHandSlot: 12760,
            LegSlot: 6202,
            BackSlot: 7784,
            TrinketSlot: 9625
        },
        {
            name: "DeathTheKid",
            faction: "Assembly",
            health: 6,
            imagination: 33,
            armor: 14,
            HeadSlot: 7364,
            TorsoSlot: 7372,
            LeftHandSlot: 12636,
            RightHandSlot: 12636,
            LegSlot: 7384,
            BackSlot: 7417,
            TrinketSlot: 12520
        },
        {
            name: "ElectricVampireCyclone",
            faction: "Paradox",
            health: 10,
            imagination: 30,
            armor: 14,
            HeadSlot: 12781,
            TorsoSlot: 12690,
            LeftHandSlot: 12597,
            RightHandSlot: 12437,
            LegSlot: 12426,
            BackSlot: 13617,
            TrinketSlot: 12598
        },
        {
            name: "Emperorfred",
            faction: "Assembly",
            health: 8,
            imagination: 42,
            armor: 13,
            HeadSlot: 7459,
            TorsoSlot: 7483,
            LeftHandSlot: 12817,
            RightHandSlot: 12817,
            LegSlot: 7507,
            BackSlot: 12923,
            TrinketSlot: 2989
        },
        {
            name: "Fred",
            faction: "Paradox",
            health: 8,
            imagination: 34,
            armor: 16,
            HeadSlot: 7450,
            TorsoSlot: 7474,
            LeftHandSlot: 12809,
            RightHandSlot: 12809,
            LegSlot: 7498,
            BackSlot: 7540,
            TrinketSlot: 12735
        },
        {
            name: "Gameking72",
            faction: "Sentinels",
            health: 8,
            imagination: 31,
            armor: 21,
            HeadSlot: 12784,
            TorsoSlot: 12748,
            LeftHandSlot: 12637,
            RightHandSlot: 12637,
            LegSlot: 12426,
            BackSlot: 12992,
            TrinketSlot: 2989
        },
        {
            name: "GlitterMagmaKitten",
            faction: "Sentinels",
            health: 5,
            imagination: 16,
            armor: 10,
            HeadSlot: 12737,
            TorsoSlot: 12707,
            RightHandSlot: 11195,
            LegSlot: 13546,
            BackSlot: 8537,
            TrinketSlot: 12518
        },
        {
            name: "Gortee",
            faction: "Sentinels",
            health: 8,
            imagination: 31,
            armor: 20,
            HeadSlot: 12782,
            TorsoSlot: 12690,
            LeftHandSlot: 12642,
            RightHandSlot: 12642,
            LegSlot: 12426,
            BackSlot: 13617,
            TrinketSlot: 12735
        },
        {
            name: "Hurricane",
            faction: "Venture",
            health: 10,
            imagination: 36,
            armor: 13,
            HeadSlot: 7465,
            TorsoSlot: 7489,
            LeftHandSlot: 7537,
            RightHandSlot: 7537,
            LegSlot: 7513,
            BackSlot: 7543,
            TrinketSlot: 9626
        },
        {
            name: "IronHonoredWarrior",
            health: 5,
            imagination: 9,
            armor: 3,
            HeadSlot: 8546,
            TorsoSlot: 3010,
            LeftHandSlot: 11272,
            RightHandSlot: 8505,
            LegSlot: 8666,
            BackSlot: 6921,
        },
        {
            name: "JoshW",
            faction: "Sentinels",
            health: 5,
            imagination: 16,
            armor: 7,
            HeadSlot: 12785,
            TorsoSlot: 12758,
            LeftHandSlot: 12486,
            RightHandSlot: 12486,
            LegSlot: 2524,
            BackSlot: 12101,
        },
        {
            name: "Klementine",
            faction: "Sentinels",
            health: 8,
            imagination: 25,
            armor: 4,
            TorsoSlot: 12702,
            LegSlot: 2523,
        },
        {
            name: "legocastlebuilder",
            faction: "Sentinels",
            health: 8,
            imagination: 26,
            armor: 5,
            HeadSlot: 7731,
            TorsoSlot: 8612,
            LeftHandSlot: 2634,
            RightHandSlot: 12316,
            LegSlot: 8644,
            BackSlot: 8684,
            TrinketSlot: 9623
        },
        {
            name: "Lol",
            faction: "Assembly",
            health: 8,
            imagination: 36,
            armor: 13,
            HeadSlot: 7364,
            TorsoSlot: 7372,
            LeftHandSlot: 7406,
            RightHandSlot: 7396,
            LegSlot: 7384,
            BackSlot: 7417,
            TrinketSlot: 2989
        },
        {
            name: "Lola",
            faction: "Assembly",
            health: 4,
            imagination: 11,
            armor: 3,
            HeadSlot: 7356,
            TorsoSlot: 7370,
            LeftHandSlot: 11272,
            RightHandSlot: 7394,
            LegSlot: 7382,
        },
        {
            name: "LordBrocktree",
            faction: "Sentinels",
            health: 10,
            imagination: 41,
            armor: 23,
            HeadSlot: 12786,
            TorsoSlot: 12754,
            LeftHandSlot: 12637,
            RightHandSlot: 12637,
            LegSlot: 12426,
            BackSlot: 12095,
        },
        {
            name: "LordBrocktree",
            faction: "Sentinels",
            health: 10,
            imagination: 35,
            armor: 17,
            HeadSlot: 13525,
            TorsoSlot: 12748,
            LeftHandSlot: 13580,
            RightHandSlot: 7516,
            LegSlot: 12426,
            BackSlot: 15994,
            TrinketSlot: 2996
        },
        {
            name: "maestro35",
            faction: "Sentinels",
            health: 10,
            imagination: 28,
            armor: 12,
            TorsoSlot: 12950,
            LeftHandSlot: 12669,
            RightHandSlot: 12968,
            LegSlot: 12951,
            BackSlot: 16179,
            TrinketSlot: 12735
        },
        {
            name: "MagicGravelStorm",
            faction: "Assembly",
            health: 4,
            imagination: 22,
            armor: 11,
            HeadSlot: 2642,
            TorsoSlot: 8356,
            LeftHandSlot: 8356,
            RightHandSlot: 8356,
            LegSlot: 8642,
            BackSlot: 2642,
        },
        {
            name: "MasterEricAssembly",
            faction: "Assembly",
            health: 10,
            imagination: 38,
            armor: 12,
            HeadSlot: 12924,
            TorsoSlot: 12945,
            LeftHandSlot: 12922,
            RightHandSlot: 12921,
            LegSlot: 12946,
            BackSlot: 12923,
            TrinketSlot: 7989
        },
        {
            name: "Max37",
            faction: "Venture",
            health: 6,
            imagination: 22,
            armor: 7,
            HeadSlot: 7365,
            TorsoSlot: 7377,
            LeftHandSlot: 7401,
            RightHandSlot: 7401,
            LegSlot: 7389,
            BackSlot: 8467,
            TrinketSlot: 12735
        },
        {
            name: "michaelvortex",
            faction: "Assembly",
            health: 10,
            imagination: 31,
            armor: 6,
            HeadSlot: 8540,
            TorsoSlot: 8597,
            LeftHandSlot: 8532,
            RightHandSlot: 11432,
            LegSlot: 8642,
            BackSlot: 6854,
            TrinketSlot: 5833
        },
        {
            name: "Mightyslickpancake",
            faction: "Assembly",
            health: 10,
            imagination: 38,
            armor: 11,
            TorsoSlot: 7372,
            LeftHandSlot: 12636,
            RightHandSlot: 12636,
            LegSlot: 7384,
            BackSlot: 7417,
            TrinketSlot: 12735
        },
        {
            name: "Mightyslickpancakes",
            faction: "Sentinels",
            health: 10,
            imagination: 37,
            armor: 16,
            HeadSlot: 7444,
            TorsoSlot: 7468,
            LeftHandSlot: 7545,
            RightHandSlot: 7516,
            LegSlot: 7492,
            BackSlot: 7538,
            TrinketSlot: 12735
        },
        {
            name: "Mightyslickpancakez",
            faction: "Paradox",
            health: 10,
            imagination: 31,
            armor: 4,
            HeadSlot: 8540,
            TorsoSlot: 8609,
            LeftHandSlot: 14569,
            LegSlot: 7387,
            BackSlot: 8539,
            TrinketSlot: 12735
        },
        {
            name: "Milligan",
            faction: "Paradox",
            health: 11,
            imagination: 38,
            armor: 14,
            HeadSlot: 12983,
            TorsoSlot: 12958,
            LeftHandSlot: 12816,
            RightHandSlot: 12816,
            LegSlot: 12959,
            BackSlot: 12989,
            TrinketSlot: 12735
        },
        {
            name: "Murphy",
            faction: "Venture",
            health: 11,
            imagination: 37,
            armor: 14,
            HeadSlot: 12981,
            TorsoSlot: 7378,
            LeftHandSlot: 12638,
            RightHandSlot: 12638,
            LegSlot: 7390,
            BackSlot: 12990,
            TrinketSlot: 12735
        },
        {
            name: "Nebula",
            faction: "Sentinels",
            health: 8,
            imagination: 23,
            armor: 0,
            HeadSlot: 8424,
            TorsoSlot: 4227,
            LegSlot: 2524,
            TrinketSlot: 2989
        },
        {
            name: "NineWellsFromStorm",
            faction: "Venture",
            health: 10,
            imagination: 36,
            armor: 12,
            HeadSlot: 7790,
            TorsoSlot: 7489,
            LeftHandSlot: 7537,
            RightHandSlot: 7537,
            LegSlot: 12957,
            BackSlot: 12995,
            TrinketSlot: 2620
        },
        {
            name: "PANGOLIN2",
            faction: "Venture",
            health: 11,
            imagination: 38,
            armor: 16,
            HeadSlot: 13525,
            TorsoSlot: 7378,
            LeftHandSlot: 12638,
            RightHandSlot: 12638,
            LegSlot: 12426,
            BackSlot: 13617,
            TrinketSlot: 16035
        },
        {
            name: "Parker",
            faction: "Sentinels",
            health: 4,
            imagination: 7,
            armor: 3,
            HeadSlot: 7355,
            TorsoSlot: 7367,
            LeftHandSlot: 11272,
            RightHandSlot: 3015,
            LegSlot: 7379,
        },
        {
            name: "radientelectronlava",
            faction: "Assembly",
            health: 4,
            imagination: 14,
            armor: 4,
            HeadSlot: 7356,
            TorsoSlot: 7370,
            LeftHandSlot: 7405,
            RightHandSlot: 7394,
            LegSlot: 7382,
            BackSlot: 8639,
        },
        {
            name: "Reminal",
            faction: "Paradox",
            health: 8,
            imagination: 24,
            armor: 16,
            HeadSlot: 12784,
            TorsoSlot: 12690,
            LeftHandSlot: 12672,
            RightHandSlot: 11195,
            LegSlot: 8642,
            BackSlot: 7540,
            TrinketSlot: 8367
        },
        {
            name: "ReynieMuldoon",
            faction: "Venture",
            health: 7,
            imagination: 27,
            armor: 12,
            HeadSlot: 12981,
            TorsoSlot: 12954,
            LeftHandSlot: 12982,
            RightHandSlot: 12982,
            LegSlot: 12955,
            BackSlot: 12990,
            TrinketSlot: 7778
        },
        {
            name: "Rickrogue",
            faction: "Paradox",
            health: 12,
            imagination: 37,
            armor: 16,
            HeadSlot: 7450,
            TorsoSlot: 7474,
            LeftHandSlot: 12809,
            RightHandSlot: 12809,
            LegSlot: 7498,
            BackSlot: 16188,
            TrinketSlot: 13576
        },
        {
            name: "Rubberfrog",
            faction: "Sentinels",
            health: 10,
            imagination: 29,
            armor: 6,
            HeadSlot: 12781,
            TorsoSlot: 12707,
            LegSlot: 12771,
            TrinketSlot: 13574
        },
        {
            name: "SlightlyBlunderVortex",
            faction: "Sentinels",
            health: 4,
            imagination: 14,
            armor: 7,
            HeadSlot: 7446,
            TorsoSlot: 7470,
            LeftHandSlot: 7546,
            RightHandSlot: 7518,
            LegSlot: 7494,
        },
        {
            name: "SlightlyBlunderVortex",
            faction: "Sentinels",
            health: 4,
            imagination: 19,
            armor: 11,
            HeadSlot: 7446,
            TorsoSlot: 7470,
            LeftHandSlot: 7546,
            RightHandSlot: 7518,
            LegSlot: 7494,
            BackSlot: 8458,
        },
        {
            name: "SupaCoopa",
            faction: "Sentinels",
            health: 8,
            imagination: 33,
            armor: 20,
            HeadSlot: 8548,
            TorsoSlot: 12690,
            LeftHandSlot: 12613,
            RightHandSlot: 12730,
            LegSlot: 12426,
            BackSlot: 13617,
            TrinketSlot: 2946
        },
        {
            name: "TheVentureDude",
            faction: "Venture",
            health: 10,
            imagination: 36,
            armor: 15,
            HeadSlot: 12993,
            TorsoSlot: 12956,
            LeftHandSlot: 12647,
            RightHandSlot: 12647,
            LegSlot: 12957,
            BackSlot: 12995,
            TrinketSlot: 13577
        },
        {
            name: "Wafflnator",
            faction: "Assembly",
            health: 7,
            imagination: 34,
            armor: 14,
            HeadSlot: 7364,
            TorsoSlot: 7372,
            LeftHandSlot: 12636,
            RightHandSlot: 12636,
            LegSlot: 7384,
            BackSlot: 7417,
            TrinketSlot: 13575
        },
        {
            name: "Xxim",
            faction: "Paradox",
            health: 4,
            imagination: 20,
            armor: 10,
            HeadSlot: 12769,
            TorsoSlot: 16615,
            RightHandSlot: 7521,
            LegSlot: 16616,
            BackSlot: 8460,
        },
        {
            name: "Xxim",
            faction: "Paradox",
            health: 6,
            imagination: 21,
            armor: 10,
            HeadSlot: 12986,
            TorsoSlot: 12960,
            LeftHandSlot: 7548,
            RightHandSlot: 12987,
            LegSlot: 12961,
            BackSlot: 12991,
        },
        {
            name: "zill",
            faction: "Sentinels",
            health: 8,
            imagination: 20,
            armor: 12,
            HeadSlot: 7447,
            TorsoSlot: 7470,
            LeftHandSlot: 12677,
            RightHandSlot: 10434,
            LegSlot: 7494,
            BackSlot: 8458,
            TrinketSlot: 2994
        },
        {
            name: "Calvin",
            faction: "Venture",
            health: 8,
            imagination: 25,
            armor: 4,
            HeadSlot: 7464,
            TorsoSlot: 7488,
            LeftHandSlot: 3066,
            RightHandSlot: 3015,
            LegSlot: 7512,
            BackSlot: 8466,
        },
        {
            name: "NineWells",
            faction: "Sentinels",
            health: 15,
            imagination: 38,
            armor: 11,
            HeadSlot: 7790,
            TorsoSlot: 12686,
            LeftHandSlot: 12678,
            RightHandSlot: 12802,
            LegSlot: 13000,
            BackSlot: 12095,
            TrinketSlot: 2989
        }
    ]
};

export { }
