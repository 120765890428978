<template>
  <div id="wrapper">
    <div id="warning">
      This is an Archive! The LEGO Group has not endorsed or authorized the
      operation of this website. Information presented as-is, no accuracy
      implied. All rights reserved
    </div>
    <div id="app" :class="style">
      <div id="body">
        <main-navigation></main-navigation>
        <div id="topAreaContainer"></div>
        <router-view />
      </div>
      <logos-outer></logos-outer>
    </div>
  </div>
</template>

<script lang="ts">
import MainNavigation from "@/components/MainNavigation.vue";
import LogosOuter from "@/components/LogosOuter.vue";
import { RouteLocation } from "vue-router";

const App = {
  data() {
    return { style: ["layout-dark"] };
  },
  components: {
    MainNavigation,
    LogosOuter,
  },
  watch: {
    $route(this: { style: string[] }, val: RouteLocation) {
      this.style.splice(0);
      val.matched.forEach((route) => {
        if (route.hasOwnProperty("meta")) {
          let meta = route.meta;
          if (meta.hasOwnProperty("rootClass")) {
            let rootClass = meta["rootClass"] as string[];
            rootClass.forEach((b) => {
              this.style.push(b);
            });
          }
        }
      });
      if (this.style.length == 0) {
        this.style.push("layout-dark");
      }
    },
  },
};
export default App;
</script>

<style lang="scss">
#warning {
  position: fixed;
  padding: 10px;
  background-color: yellow;
  width: 100%;
  top: 0;
  z-index: 100000;
  height: 35px;
  box-sizing: border-box;
  font-weight: bold;
}

#wrapper {
  padding-top: 35px;
}

/* HEADING */
#mainHeading {
  height: 70px;
  position: relative;
  width: 950px;
  display: block;
}

#mainHeading h1 {
  display: block;
  height: 85px;
  line-height: 85px;
  font-size: 200%;
  font-family: "Arial Rounded MT Bold", Arial, Sans-Serif;
  margin: 0;
  padding: 0 0 0 15px;
}

.layout-dark #siteContainer {
  background-image: url(assets/dark/main-heading.png);
}
</style>

<style lang="scss">
@import "@/assets/css/layout-light.scss";
@import "@/assets/css/layout-dark.scss";
@import "@/assets/css/main.scss";
@import "@/assets/css/nunito.scss";

h1,
h2,
h3 {
  font-family: "Nunito", sans-serif;
}
</style>
