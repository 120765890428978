
import { CSSProperties } from "vue";
interface SlideProps {
  id: number;
  img?: string;
  title?: string;
  headline1?: string;
  headline2?: string;
  text1?: string;
  link?: string;
  linkText?: string;
  video?: string;
  videoPos?: "A" | "B" | "C";
  width?: number;
}
type CSSClasses = { [key: string]: boolean };

const VIDEO_ATTRS = {
  A: {
    width: 303,
    height: 200,
  },
  B: {
    width: 308,
    height: 198,
  },
  C: {
    width: 313,
    height: 174,
  },
};

export default {
  props: [
    "id",
    "img",
    "title",
    "headline1",
    "headline2",
    "text1",
    "link",
    "linkText",
    "video",
    "videoPos",
    "width",
  ],
  setup(props: SlideProps) {
    const id = props.id;
    const cssClass: CSSClasses = {
      "slideshow-slide": true,
    };
    cssClass[`slide-${id}`] = true;
    if (props.videoPos) {
      cssClass[`video-pos-${props.videoPos}`] = true;
    }
    const sty = {
      width: (props.width || 320) + "px",
    };
    const videoAttrs = VIDEO_ATTRS[props.videoPos || "A"];

    return { sty, cssClass, videoAttrs, ...props };
  },
};
