
import { ref, onMounted, Ref, onUpdated, computed, nextTick } from "vue";

const CUTOFF = 800;

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup(props: {title?: string}) {
    const title = ref(props.title);

    const height: Ref<number> = ref(300);
    const row: Ref = ref<HTMLElement>();

    const clazz = computed(() => {
      return {
        "column-row-3": height.value < 200,
        "column-row-1": 200 <= height.value && height.value < 1000,
        "column-row-1-1": 1000 <= height.value && height.value < 2000,
        "column-row-1-2": 2000 <= height.value,
      };
    });

    function checkSize() {
      if (row.value) {
        height.value = row.value.offsetHeight;
      }
    }

    onMounted(() => nextTick(checkSize));
    onUpdated(() => nextTick(checkSize));

    return { title, row, clazz };
  },
};
