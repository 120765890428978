const PARENTS_ROUTES = {
  path: '/parents/',
  component: () => import(/* webpackChunkName: "parents" */ '../views/Parents.vue'),
  meta: {
    breadcrumbs: [
      { title: "LEGO Universe", href: "/" },
      { title: "Parents", href: "/parents" }
    ],
    rootClass: ["background-3", "layout-light"],
  },
  children: [
    {
      path: '',
      name: 'parents',
      component: () => import(/* webpackChunkName: "parents-default" */ '../views/parents/Default.vue'),
    },
    {
      path: 'player-safety',
      name: 'parents-playersafety',
      component: () => import(/* webpackChunkName: "parents-playersafety" */ '../views/parents/PlayerSafety.vue'),
      meta: {
        breadcrumbs: [
          { title: "Safety", href: "/parents/player-safety" },
          { title: "Player Safety", href: "/parents/player-safety" }
        ]
      },
    },
    {
      path: 'safety-faq',
      name: 'parents-safetyfaq',
      component: () => import(/* webpackChunkName: "parents-safetyfaq" */ '../views/parents/SafetyFAQ.vue'),
      meta: {
        breadcrumbs: [
          { title: "Safety", href: "/parents/player-safety" },
          { title: "Safety Questions", href: "/parents/safety-faq" }
        ]
      },
    },
    {
      path: 'safety-tips/',
      name: 'parents-safetytips',
      component: () => import(/* webpackChunkName: "parents-safetytips" */ '../views/parents/SafetyTips.vue'),
      meta: {
        breadcrumbs: [
          { title: "Safety", href: "/parents/player-safety" },
          { title: "Safety Tips", href: "/parents/safety-tips" }
        ]
      },
      children: [
        {
          path: ':id',
          name: 'parents-safetytip',
          component: () => import('../components/modules/Missing.vue'),
        }
      ]
    },
    {
      path: 'code-of-conduct',
      name: 'parents-coc',
      component: () => import(/* webpackChunkName: "parents-coc" */ '../views/parents/CodeOfConduct.vue'),
      meta: {
        breadcrumbs: [
          { title: "Safety", href: "/parents/player-coc" },
          { title: "Code of Conduct", href: "/parents/code-of-conduct" }
        ]
      },
    },
    {
      path: 'where-to-buy',
      component: () => import(/* webpackChunkName: "parents-wheretobuy" */ '../views/parents/WhereToBuy.vue'),
      meta: {
        breadcrumbs: [
          { title: "Where to Buy", href: "/parents/where-to-buy/232706" },
        ]
      },
      children: [
        {
          path: '232706',
          name: 'parents-gamebox',
          component: () => import(/* webpackChunkName: "parents-gamebox" */ '../views/parents/GameBox.vue'),
          meta: {
            breadcrumbs: [
              { title: "Game Box", href: "/parents/where-to-buy/232706" },
            ]
          },
        },
        {
          path: ':pathMatch(.*)*',
          component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        }
      ],
    },
    {
      path: 'subscription',
      name: 'parents-subscription',
      component: () => import(/* webpackChunkName: "parents-subscription" */ '../views/parents/Subscription.vue'),
      meta: {
        breadcrumbs: [
          { title: "Membership", href: "/parents/subscription" },
        ]
      },
    },
    {
      path: 'subscription-faq',
      name: 'parents-subscriptionfaq',
      component: () => import(/* webpackChunkName: "parents-subscriptionfaq" */ '../views/parents/SubscriptionFAQ.vue'),
      meta: {
        breadcrumbs: [
          { title: "Membership", href: "/parents/subscription" },
          { title: "Membership Questions", href: "/parents/subscription-faq" },
        ]
      },
    },
    {
      path: 'accolades',
      name: 'parents-accolades',
      component: () => import(/* webpackChunkName: "parents-accolades" */ '../views/parents/Accolades.vue'),
      meta: {
        breadcrumbs: [
          { title: "Accolades", href: "/parents/accolades" },
        ]
      },
    },
    {
      path: ':pathMatch(.*)*',
      component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    },
  ]
};
export { PARENTS_ROUTES };