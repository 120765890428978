<template>
  <div class="column-row" :class="clazz">
    <div class="column column-large first last" ref="row">
      <div class="column-top">
        <h1 v-if="title">{{ title }}</h1>
      </div>
      <div class="column-content">
        <!--<div class="negative-row">-->
          <slot></slot>
        <!--</div>-->
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, onUpdated, computed, nextTick } from "vue";

const CUTOFF = 800;

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup(props: {title?: string}) {
    const title = ref(props.title);

    const height: Ref<number> = ref(300);
    const row: Ref = ref<HTMLElement>();

    const clazz = computed(() => {
      return {
        "column-row-3": height.value < 200,
        "column-row-1": 200 <= height.value && height.value < 1000,
        "column-row-1-1": 1000 <= height.value && height.value < 2000,
        "column-row-1-2": 2000 <= height.value,
      };
    });

    function checkSize() {
      if (row.value) {
        height.value = row.value.offsetHeight;
      }
    }

    onMounted(() => nextTick(checkSize));
    onUpdated(() => nextTick(checkSize));

    return { title, row, clazz };
  },
};
</script>

<style scoped lang="scss">
  .column-top {
    h1 {
      font-weight: 800;
    }
  }

  .column-row-3 {
    .column-content {
      min-height: 60px;
    }
  }
</style>
