
import MainNavigation from "@/components/MainNavigation.vue";
import LogosOuter from "@/components/LogosOuter.vue";
import { RouteLocation } from "vue-router";

const App = {
  data() {
    return { style: ["layout-dark"] };
  },
  components: {
    MainNavigation,
    LogosOuter,
  },
  watch: {
    $route(this: { style: string[] }, val: RouteLocation) {
      this.style.splice(0);
      val.matched.forEach((route) => {
        if (route.hasOwnProperty("meta")) {
          let meta = route.meta;
          if (meta.hasOwnProperty("rootClass")) {
            let rootClass = meta["rootClass"] as string[];
            rootClass.forEach((b) => {
              this.style.push(b);
            });
          }
        }
      });
      if (this.style.length == 0) {
        this.style.push("layout-dark");
      }
    },
  },
};
export default App;
