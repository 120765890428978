import { RouteLocationRaw } from "vue-router";

export interface Category {
  title: string,
  href: RouteLocationRaw,
  storyIDs: number[],
  top3StoryIDs?: number[],
};

const CATEGORY_RELNOTES = {
  title: "Release Notes",
  href: "/community/release-notes",
};
const CATEGORY_GAME = {
  title: "Game News",
  href: {name: 'category', params: {key: 'game-news'}}
};
const CATEGORY_VIDEOS = { // 68622
  title: "Videos",
  href: {name: 'category', params: {key: 'videos'}},
  name: "videos",
};
const CATEGORY_CONCEPT_ART = { // 68623
  title: "Concept Art",
  href: {name: 'media-category', params: {sec: 'images', key: 'concept-art'}}
};
const CATEGORY_EVENTS = { // 68624
  title: "Events",
  href: {name: 'category', params: {key: 'events'}},
};
const CATEGORY_CHALLENGES = { // 68625
  title: "Challenges",
  href: {name: 'category', params: {key: 'challenges'}},
};
const CATEGORY_POLLS = { // 70536
  title: "Polls",
  href: {name: 'category', params: {key: 'polls'}}
};
const CATEGORY_GENERAL = { // 71800
  title: "General News",
  href: {name: 'category', params: {key: 'general-news'}},
  name: "general-news",
};
const CATEGORY_MLN = { // 103202
  title: "My LEGO Network",
  href: {name: 'category', params: {key: 'my-lego-network'}},
  name: "my-lego-network",
};

const CATEGORIES: Record<string, Category> = {
  "challenges": {
    title: "Challenges",
    href: {name: 'category', params: {key: 'challenges'}},
    storyIDs: [
      187316, 176246, 171161, 173107, 171485, 169331,
      166210, 163386, 154387, 143101, 136632, 134387,
      131979, 132229, 127603, 124812, 124215, 118071,
      118068, 114947, 110976, 109177, 109176, 99193,
    ]
  },
  "concept-art": {
    title: "Concept Art",
    href: {name: 'media-category', params: {sec: 'images', key: 'concept-art'}},
    storyIDs: [
      74149, 127604, 118021,
    ]
  },
  "events": {
    title: "Events",
    href: {name: 'category', params: {key: 'events'}},
    storyIDs: [
      341436,
      344849, 342758, 343424, 343093, 342862, 342751, 341784, 341234, 340371,

      316343,
      315965, 315474, 315045, 314632, 314552, 314417, 313300, 312599, 311443,

      229903, 228135, 226028, 218090, 216700, 213809, 211742, 208881, 200347,
      200089, 195239, 187312, 168267, 160285,

      140026, 137083,

      150739,

      135467, /*150757,*/
      124154,
      121617, /*150764,*/
      109151,
      107596,

      150784,
      150781,
      150787,

      92825, /*150789,*/
      68373,
    ]
  },
  "game-news": {
    title: "Game News",
    href: {name: 'category', params: {key: 'game-news'}},
    storyIDs: [
      187324,
      187315,
      179554,
    ],
  },
  "general-news": {
    title: "General News",
    href: {name: 'category', params: {key: 'general-news'}},
    storyIDs: [
      355487, 232954, 228532, 226839, 226080, 213807, 200928, 199707, 198139,
      195837, 191873, 190618,

      177816,

      175134, 175368, 170558, 175133, 173110, 171484,

      171027, 168575,
      158466, 154386,

      149946, 150531, 150530, 150524, 150030, 148676, 148675, 147671, 147678,
      144087, 144934, 143098, 138718, 141072, 139108, 135138,

      132675, 129539, 127606, 127607,

      124811, 123181, 124555, 118067, 116401, 115237,
      110972, 110943, 110944, 109069, 107581, 106760, 106758, 98752, 101699,
      97861, 97860, 97330, 95809, 92302, 93749, 91070, 90419, 88803, 88481,
      88019, 82873, 82861, 80861, 80859, 78887, 74162, 74160, 73045, 73047,
      73043, 73044, 71807, 71808, 71802, 71799,
    ],
    top3StoryIDs: [262670, 261633, 232972],
  },
  "polls": {
    title: "Polls",
    href: {name: 'category', params: {key: 'polls'}},
    storyIDs: [
      171024, 160073, 149369, 149370, 139110, 124810,
      118093, 110971, 99194,
    ],
  },
  "videos": {
    title: "Videos",
    href: {name: 'category', params: {key: 'videos'}},
    storyIDs: [
      98751,
    ]
  },
  "my-lego-network": {
    title: "My LEGO Network",
    href: {name: 'category', params: {key: 'my-lego-network'}},
    storyIDs: [
      97867, 97866, 103092,
    ]
  },
};
export {
  CATEGORIES,
  CATEGORY_CHALLENGES,
  CATEGORY_CONCEPT_ART,
  CATEGORY_EVENTS,
  CATEGORY_GAME,
  CATEGORY_GENERAL,
  CATEGORY_MLN,
  CATEGORY_POLLS,
  CATEGORY_RELNOTES,
  CATEGORY_VIDEOS,
};
