<template>
  <div class="column-row column-row-2">
    <div class="column column-large first last">
      <div class="column-top"></div>
      <div
        id="ctl00_ContentPlaceHolderUniverse_SlideShowContainer"
        class="column-content"
      >
        <slideshow :slides="slides"></slideshow>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Slideshow from '@/components/Slideshow.vue';

export default {
  props: ["slides"],
  components: {
      Slideshow
  }
};
</script>