
import { Link } from "@/options";
import { RouteLocation } from "vue-router";

interface BreadcrumbsData {
  routes: Link[]
}

export default {
  data(): BreadcrumbsData {
    return {
      routes: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(this: BreadcrumbsData, route: RouteLocation) {
        let data = new Array();
        route.matched.forEach((match) => {
          if (match.hasOwnProperty("meta")) {
            let meta = match.meta;
            if (meta.hasOwnProperty("breadcrumbs")) {
              let breadcrumbs = meta.breadcrumbs as Link[];
              breadcrumbs.forEach((b: any) => {
                data.push(b);
              });
            }
            if (meta.hasOwnProperty("breadcrumbFn")) {
              let breadcrumbFn = meta.breadcrumbFn as CallableFunction;
              breadcrumbFn(route.params).forEach((b: Link) => {
                data.push(b);
              });
            }
          }
          this.routes = data;
        });
      },
    },
  },
  props: [],
};
