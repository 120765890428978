import { Link } from '@/options';
import { CATEGORIES } from '@/stories/categories';

const SECTIONS: Record<string, Link> = {
    'images': {
        title: "Images",
        href: { name: "media-section", params: { 'sec': 'images' } },
    }
};

function sectionBreadcrumbs(params: Record<string, any>): Link[] {
    let section = SECTIONS[params.sec];
    return section ? [section] : [];
}

function categoryBreadcrumbs(params: any): any[] {
    let category = CATEGORIES[params.key];
    if (category) {
        return [category];
    } else {
        return [];
    }
}

const MEDIA_ROUTES = {
    path: '/media/',
    component: () => import(/* webpackChunkName: "media" */ '../views/Media.vue'),
    meta: {
        breadcrumbs: [
            { title: "LEGO Universe", href: "/" },
            { title: "Media", href: "/media" }
        ],
        rootClass: ["background-8", "layout-dark"],
    },
    children: [
        {
            path: 's/:sec',
            name: 'media-section',
            component: () => import(/* webpackChunkName: "media-sections" */ '../views/community/NewsNetwork.vue'),
            meta: {
                breadcrumbFn: sectionBreadcrumbs,
            },
            children: [
                {
                    path: 'c/:key',
                    name: 'media-category',
                    component: () => import(/* webpackChunkName: "media-category" */ '../views/community/news-network/Category.vue'),
                    meta: {
                        breadcrumbFn: categoryBreadcrumbs,
                    },
                },
                {
                    path: ':pathMatch(.*)*',
                    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
                },
            ]
        },
        {
            path: ':pathMatch(.*)*',
            component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        },
    ]
};
export { MEDIA_ROUTES };