<template>
  <div id="siteContainer" class="home">
    <div id="mainColumn1" class="wide">
      <div id="SplashContainer">
        <div id="FlashContainer">
          <img id="FlashImage" src="../assets/splash-html/flash-image.png" alt="" width="950" height="660">
          <div id="FlashMainStage"></div>
          <router-link to="/the-game" id="MainLink"><img id="Img1" src="../assets/splash-html/main-link.png" alt="Join the amazing online adventure today! Available for PC and Mac!"></router-link>
        </div>
        <div id="BannerContainer">

          <router-link class="banner" to="/shop-55000">
            <span><img src="../assets/splash-html/heading-special-offer.png" alt="SPECIAL OFFER" width="230" height="34"></span>
            <img src="../assets/splash-html/banner-special-offer.png" alt="SPECIAL OFFER" width="230" height="166">
            <p class="heading">DVD + 1 month of play for £7.49!</p>
          </router-link>

          <router-link class="banner" to="/community/news-network/s/318012">
            <span><img src="../assets/splash-html/heading-new-features.png" alt="NEW GAME FEATURES" width="230" height="34"></span>
            <img src="../assets/splash-html/banner-new-features.png" alt="NEW GAME FEATURES" width="230" height="166">
            <p class="heading">Nexus Tower is now live!</p>
          </router-link>

          <router-link class="banner" to="/media/258110">
            <span><img src="../assets/splash-html/heading-videos.png" alt="WATCH VIDEO" width="230" height="34"></span>
            <img src="../assets/splash-html/banner-videos.png" alt="WATCH VIDEO" width="230" height="166">
            <p class="heading">How to start playing</p>
          </router-link>

          <router-link class="banner" to="/parents/subscription">
            <span><img src="../assets/splash-html/heading-membership.png" alt="MEMBERSHIP" width="230" height="34"></span>
            <img src="../assets/splash-html/banner-membership.png" alt="MEMBERSHIP" width="230" height="166">
            <p class="heading">Continue the fun!</p>
          </router-link>

        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<style scoped type="text/css">
  #siteContainer {
    background: none;
  }

  #SplashContainer {
    width: 950px;
    height: 810px;
    position: relative;
    margin-top: -170px;
  }

  #FlashContainer {
    height:660px;
    width:950px;
    position:relative;
  }

  #FlashMainStage {
    position:absolute;
    top:0;
    left:0;
  }

  #MainLink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: visible;
    border: 0;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    display: block;
    z-index: 1000;
    zoom: 1;
  }

  #Bubble:before {
    content:"";
    display:block;
    position:absolute;
    bottom:-20px;
    left:20px;
    width:0;
    height:0;
    border:10px solid transparent;
    border-top-color:#000;
  }

  #BannerContainer {
    height:180px;
    width:970px;
    text-align:center;
    position:relative;
    left:-10px;
    z-index:2000;
    top:-80px;
  }

  .banner {
    width:230px;
    height:220px;
    display:inline-block;
    text-align:left;
    margin:-30px 3px 0 3px;
    position:relative;
    border:0 !important;
    background:url(/web/20110804174822im_/http://cache.lego.com/universe/images/splash/splashtoutshadow.png) bottom center no-repeat;
  }

  .banner > span {
    position:absolute;
    top:0;
    z-index:888;
  }

  .banner > img {
    position:absolute;
    bottom:40px;
    z-index:777;
    border:0;
  }

  .banner > p {
    margin:0;
    padding:0;
    color:#fff;
    position:absolute;
    left:0;
    bottom:44px;
    text-align:center;
    z-index:888;
    width:230px;
    line-height: 22px;
    font-weight:bold;
  }
</style>

<script>
export default {
  name: 'home',
  components: {}
}
</script>
