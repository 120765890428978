<template>
  <span class="nexuslink"
    >Created by
    <strong
      ><router-link
        :to="{
          name: 'nexus-hq-character-detail',
          params: {
            universe: user.universe,
            user: user.key,
          },
        }"
        >{{ user.name }}</router-link
      ></strong
    ></span
  >
</template>

<style lang="scss">
.nexuslink {
  display: inline-block;
  line-height: 28px;
  height: 25px;
  position: relative;
  top: -5px;

  a {
    display: inline-block;
    height: 25px;
    background: url(../../assets/community/nexuslink.png) top right no-repeat;
    padding-right: 25px;
    color: #1f81c0;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["user"],
  setup() {},
});
</script>
