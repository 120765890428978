import { RouteRecordRaw } from 'vue-router';

const NEXUSHQ_ROUTES: RouteRecordRaw = {
    path: '/nexus-hq/',
    component: () => import(/* webpackChunkName: "nexus-hq" */ '../views/NexusHQ.vue'),
    meta: {
        breadcrumbs: [
            { title: "LEGO Universe", href: "/" },
            { title: "Community", href: "/community" },
            { title: "Nexus HQ", href: "/nexus-hq" }
        ],
        rootClass: ["background-4", "layout-dark"],
    },
    children: [
        {
            path: '',
            name: 'nexus-hq',
            //component: () => import(/* webpackChunkName: "nexus-hq-default" */ '../views/community/nexus-hq/Default.vue'),
            redirect: '/nexus-hq/home/index',
        },
        {
            path: 'home/index',
            name: 'nexus-hq-home-index',
            component: () => import(/* webpackChunkName: "nexus-hq-home-index" */ '../views/community/nexus-hq/Default.vue'),
        },
        {
            path: "character/search/:uid/:query",
            name: 'nexus-hq-character-search',
            component: () => import(/* webpackChunkName: "nexus-hq-character-search" */ '../views/community/nexus-hq/CharacterSearch.vue'),
        },
        {
            path: "character/details/:universe/:user",
            name: 'nexus-hq-character-detail',
            component: () => import(/* webpackChunkName: "nexus-hq-character-detail" */ '../views/community/nexus-hq/CharacterDetail.vue'),
        },
        {
            path: "item/search/:query",
            name: 'nexus-hq-item-search',
            component: () => import(/* webpackChunkName: "nexus-hq-item-search" */ '../views/community/nexus-hq/ItemSearch.vue'),
        },
        {
            path: "leaderboard/search/:uid/:activity/:query",
            name: 'nexus-hq-leaderboard-search',
            component: () => import(/* webpackChunkName: "nexus-hq-leaderboard-search" */ '../views/community/nexus-hq/LeaderboardSearch.vue'),
        },
        {
            path: ':pathMatch(.*)*',
            component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        }
    ]
};

export { NEXUSHQ_ROUTES };