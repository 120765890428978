<template>
  <div class="pollimages">
    <div class="answer-img" v-for="a in poll.answers">
      <img class="sq-137 rounded" :src="a.image">
    </div>
    <div v-for="a in poll.answers" class="answer-short">{{a.short}}</div>
    <div style="clear: both;"></div>
  </div>
</template>

<script>
  import polls from '@/stories/polls.ts';
  export default {
    props: ["id"],
    computed: {
      poll() {
        let poll = polls[this.id];
        if (poll) {
          return poll;
        } else {
          console.warning(`Missing poll data for id: ${this.id}`);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .answer-img {
    width: 150px;
    float: left;
  }

  .answer-short {
    width: 130px;
    padding-right: 20px;
    float: left;
    text-align: center;
  }
</style>
