function pollImage(name: String) {
  return require(`@/assets/news-network/polls/${name}`);
}

const polls = {
  "00579cc7-4fd9-42d6-ad8b-1fce51718405": {
    id: "00579cc7-4fd9-42d6-ad8b-1fce51718405",
    href: { name: "story", params: {id: 171024}},
    question: "Are you a LEGO Universe BETA Tester?",
    answers: [
      {
        id: '56ebd3d9-6826-461e-a0d6-e9a2b0a16a24',
        text: "Yes! I am a LEGO Universe Closed BETA Tester!",
        short: "Yes!",
        // pic28687CB0A04604846AE313500F2EC3CF.jpg
        image: require('@/assets/news-network/poll-beta-tester-yes.jpg'),
      },
      {
        id: 'ff32e745-24a9-4dad-a282-26e99c62bea8',
        text: "I signed up, but I haven't been chosen.",
        short: "Signed up",
        // picD4297AFE84E617E5B1508FE40D2193F7.jpg
        image: require('@/assets/news-network/poll-beta-tester-signed-up.jpg'),
      },
      {
        id: 'd63d610f-4157-4491-9794-fb4e743b75e1',
        text: "I'm not interested in becoming a LEGO Universe BETA Tester.",
        short: "Not interested",
        // pic16FC9073D02C400D76A3689BEC61C72C.jpg
        image: require('@/assets/news-network/poll-beta-tester-not-interested.jpg'),
      }
    ],
  },
  "021f2ddc-c575-4b02-b00c-b830d57d145e": {
    id: "021f2ddc-c575-4b02-b00c-b830d57d145e",
    href: { name: "story", params: {id: 149370}},
    question: "Which of these friendship building features might you find the most fun?",
    answers: [
      {
        // picD517BBBA4EB88F7A73404A2CA0928F31.jpg
        id: "42a7847c-da9c-4a66-a847-e516417b7d69",
        text: "Mailing!",
        short: "Mailing!",
      },
      {
        // pic0CA474B01D216D82615769D225C32D9C.jpg
        id: "c8d875e4-4591-439e-a16a-0ef19f397f5f",
        text: "Chatting!",
        short: "Chatting!",
      },
      {
        // pic5DA783CBC20412E998E47969CD1D3038.jpg
        id: "1df8a69f-e866-4945-b403-42f973190fcc",
        text: "Battling darkness!",
        short: "Battling darkness!",
      }
    ]
  },
  "3c0ac4bd-8090-4d85-812b-e4d41a4aab39": {
    id: '3c0ac4bd-8090-4d85-812b-e4d41a4aab39',
    href: { name: "story", params: {id: 124810}},
    question: "What do you want to build a bunch of? ",
    answers: [
      {
        // pic9D6AC1FB520E2A77FA6E248E777E9F18.jpg
        id: "0d670d4a-0490-4399-9409-eabe03d55ed1",
        text: "Buildings",
        short: "Buildings",
      },
      {
        // pic916EB15904C48B236AF97DAA7C519E29.jpg
        id: "5f2c0f15-3fc6-49fe-a206-ca296eb64cfa",
        text: "Vehicles",
        short: "Vehicles",
      },
      {
        // picEF857576D4E904B44FF8F1423D08A8AC.jpg
        id: "7a49a952-f0b8-4bda-a923-327b43f5ff3a",
        text: "Creatures",
        short: "Creatures",
      }
    ]
  },
  "3c9e3e10-84fc-4de9-929f-8e74dae71ec0": {
    id: "3c9e3e10-84fc-4de9-929f-8e74dae71ec0",
    href: { name: "story", params: {id: 149369}},
    question: "What will be your favorite fun way to battle for a brighter future in the gameworld?",
    answers: [
      {
        // picF509633CA3BA589E2DA4AEB659FDE5E6.jpg
        id: "e5c2ede8-130a-4aa0-97da-ca4fdecac839",
        text: "Collecting useful loot!",
        short: "Collecting useful loot!",
      },
      {
        // pic1189E0A02C5622EB9172F248F79895A2.jpg
        id: "475becaf-e755-4fa4-a506-1da429b8d295",
        text: "Building with LEGO bricks!",
        short: "Building with LEGO bricks!",
      },
      {
        // pic1E51B9020DB76A9C96B2EBA64BEE7D2F.jpg
        id: "54fcddb3-f37d-4685-bc2a-6d0c20a5cd7d",
        text: "Smashing dark creatures!",
        short: "Smashing dark creatures!",
      }
    ]
  },
  "408c9156-3191-46f6-b194-4eeb9b111c5a": {
    id: "408c9156-3191-46f6-b194-4eeb9b111c5a",
    href: { name: "story", params: {id: 110971}},
    question: "Choose an animal to be animated by LEGO Universe artists!",
    answers: [
      {
        id: "44983572-ff9b-4dd6-8e05-958b123007fd",
        text: "Lion",
        short: "Lion",
        // pic6656E06F06580FA3B877356F5C8AD803.jpg
        image: pollImage('critter-lion.jpg'),
      },
      {
        id: "7ce05e66-3df8-4acb-9bd4-a6342fc26fc7",
        text: "Praying mantis",
        short: "Praying mantis",
        // picF29E22770BD5A218EF5046B3E8CD88A2.jpg
        image: pollImage('critter-mantis.jpg'),
      },
      {
        id: "446d0c68-a28a-4d2b-94eb-406d06342d9d",
        text: "Elephant",
        short: "Elephant",
        // picC395CD24C9645A8F2406EF1E18D75349.jpg
        image: pollImage('critter-elephant.jpg'),
      }
    ]
  },
  "92fbc139-076b-4977-83fd-da82004e6f4e": {
    id: "92fbc139-076b-4977-83fd-da82004e6f4e",
    href: { name: "story", params: {id: 139110}},
    question: "Which type would be the most fun for you to put together?",
    answers: [
      {
        id: "a8104b17-a207-4599-b83e-37a9fe1b7e8c",
        text: "Amazing LEGO minifigures!",
        short: "Amazing LEGO minifigures!",
        // pic464E2F0E891AE9AC0AEFAE6BD83F6D4C.jpg
      },
      {
        id: "6b236e39-4b23-4e27-821e-2ba98ef53cb7",
        text: "Custom LEGO cars!",
        short: "Custom LEGO cars!",
        // pic2F4F84710DC8167C56F8F312FAEA60FB.jpg
      },
      {
        id: "664f4b3f-ee7e-412e-940a-157a1dbebbbf",
        text: "Stunning LEGO spaceships!",
        short: "Stunning LEGO spaceships!",
        // pic7B75661EA4C4269335BAF49689EF7B08.jpg
      }
    ]
  },
  "bfc231eb-f792-4c4a-a862-328f5be36950": {
    id: "bfc231eb-f792-4c4a-a862-328f5be36950",
    href: { name: "story", params: {id: 160073}},
    question: "What do you find the most fun about the Great Minifig Mission?",
    answers: [
      {
        id: "95faa8bf-57fc-4e32-8594-b358b74b9f6e",
        text: "Completing missions",
        short: "Completing missions",
        // pic336528E994467117EBD2D3BFAECFDBAB.jpg
        image: require("@/assets/news-network/polls/minifig-mission-missions.jpg"),
      },{
        id: "f365dcdd-74c1-41da-a9b3-d9dd0ff1959c",
        text: "Solving mysteries",
        short: "Solving mysteries",
        // picC791A97153244B7F3250AD684CC7DF15.jpg
        image: require("@/assets/news-network/polls/minifig-mission-mysteries.jpg"),
      },{
        id: "cc4a0453-c784-4b52-83c6-b6f0c3516438",
        text: "Leaderboard competition",
        short: "Leaderboard competition",
        // pic3FB03F5CA897C9C42B3990C7AF65E8B7.jpg
        image: require("@/assets/news-network/polls/minifig-mission-leaderboard.jpg"),
      }
    ]
  },
  "c42fef6e-c6b1-4721-8f39-5683b136a672": {
    id: "c42fef6e-c6b1-4721-8f39-5683b136a672",
    href: { name: "story", params: {id: 99194}},
    question: "What should Brickkeeper's robo-dog be called?",
    answers: [
      {
        // picC2846B397876365D51EC2E307A3AED3A.jpg
        id: "99b078e3-e7c3-4e5b-b3da-06e95798eeb1",
        text: "M.U.T.T. (Motorized Universal Technology Terrier)",
        short: "M.U.T.T.",
        result: 51.1,
        image: pollImage('pet-mutt.jpg'),
      },
      {
        // picD7FF76DA6686E2952138DA438FD7FEA5.jpg
        id: "d7250596-b040-4feb-82ba-4c6871f15a35",
        text: "P.U.P. (Pneumatic Utility Partner)",
        short: "P.U.P.",
        result: 26.6,
        image: pollImage('pet-pup.jpg'),
      },
      {
        // pic0D9347A98E9B776EA10ECC166574D3C6.jpg
        id: "571712e9-374c-4eed-b35f-4995bfe384b4",
        text: "D.O.G. (Digital Operating Gizmo)",
        short: "D.O.G.",
        result: 22.3,
        image: pollImage('pet-dog.jpg'),
      }
    ]
  },
  "f834ab28-76bd-451c-868d-50741e249f57": {
    id: "f834ab28-76bd-451c-868d-50741e249f57",
    href: { name: "story", params: {id: 118093}},
    answers: [
      {
        id: "990d109a-9b4f-420e-8c6b-172d6cd010e9",
        short: "Drama",
        text: "Drama",
        image: pollImage('story-drama.jpg'),
        // picD69A775EFA12ABED87C669588BC1F23B.jpg
      },
      {
        id: "76ab753a-ce17-4c40-80e9-4febb4eb2fdf",
        short: "Mystery",
        text: "Mystery",
        image: pollImage('story-mystery.jpg'),
        // pic914ECC2706C1FACF6C8A270EA0C78505.jpg
      },
      {
        value: "6811f9bf-2f4f-4543-b220-4c552d86b337",
        short: "Adventure",
        text: "Adventure",
        image: pollImage('story-adventure.jpg'),
        // pic231AA3A362519D9154C132E642551E55.jpg
      }
    ]
  }
};
export default polls;
