import { COMMUNITY_ROUTES } from './community';
import { HELP_ROUTES } from './help';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { HOME_ROUTES } from './home';
import { THEGAME_ROUTES } from './thegame';
import { MEDIA_ROUTES } from './media';
import { NEXUSHQ_ROUTES } from './nexushq';
import { PARENTS_ROUTES } from './parents';

const ROUTES: RouteRecordRaw[] = [
  HOME_ROUTES,
  THEGAME_ROUTES,
  NEXUSHQ_ROUTES,
  COMMUNITY_ROUTES,
  MEDIA_ROUTES,
  PARENTS_ROUTES,
  HELP_ROUTES,
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = createRouter({
  routes: ROUTES,
  history: createWebHashHistory(),
});

export default router;
