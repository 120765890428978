const THEGAME_ROUTES = {
    path: '/the-game/',
    component: () => import(/* webpackChunkName: "the-game" */ '../views/TheGame.vue'),
    meta: {
        breadcrumbs: [
            { href: '/', title: 'LEGO Universe' },
            { href: '/the-game', title: 'Game Info' }
        ],
        rootClass: ["background-1", "layout-dark"],
    },
    children: [
        {
            path: '',
            name: 'the-game',
            component: () => import(/* webpackChunkName: "the-game-default" */ '../views/the-game/Default.vue'),
        },
        {
            path: 'getting-started',
            component: () => import(/* webpackChunkName: "thegame-gettingstarted" */ '../views/the-game/GettingStarted.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/getting-started', title: 'Getting Started' }
                ]
            },
            children: [
                {
                    path: '',
                    name: 'thegame-gettingstarted',
                    component: () => import(/* webpackChunkName: "thegame-gettingstarted-default" */ '../views/the-game/getting-started/Default.vue'),
                    meta: {
                        breadcrumbs: [
                            { href: '/the-game/getting-started', title: 'What is LEGO Universe?' }
                        ]
                    },
                    
                },
                {
                    path: 'free-to-play',
                    name: 'thegame-freetoplay',
                    component: () => import(/* webpackChunkName: "thegame-freetoplay" */ '../views/the-game/getting-started/FreeToPlay.vue'),
                    meta: {
                        breadcrumbs: [
                            { href: '/the-game/getting-started/free-to-play', title: 'Free to Play' }
                        ]
                    },
                    
                },
                {
                    path: 'membership',
                    name: 'thegame-membership',
                    component: () => import(/* webpackChunkName: "thegame-membership" */ '../views/the-game/getting-started/Membership.vue'),
                    meta: {
                        breadcrumbs: [
                            { href: '/the-game/getting-started/membership', title: 'Membership' }
                        ]
                    },
                    
                }
            ]
        },
        {
            path: 'build-anything',
            name: 'build-anything',
            component: () => import(/* webpackChunkName: "build-anything" */ '../views/the-game/BuildAnything.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/build-anything', title: 'The Game / Building' }
                ]
            }
        },
        {
            path: 'play',
            name: 'play',
            component: () => import(/* webpackChunkName: "thegame-play" */ '../views/the-game/Play.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/play', title: 'The Game / Play' }
                ]
            }
        },
        {
            path: 'connect',
            name: 'connect',
            component: () => import(/* webpackChunkName: "thegame-connect" */ '../views/the-game/Connect.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/play', title: 'The Game / Connect with Friends' }
                ]
            }
        },
        {
            path: 'leveling',
            name: 'leveling',
            component: () => import(/* webpackChunkName: "thegame-leveling" */ '../views/the-game/Leveling.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/leveling', title: 'The Game / Leveling' }
                ]
            }
        },
        {
            path: 'story',
            name: 'theuniverse-story',
            component: () => import(/* webpackChunkName: "theuniverse-story" */ '../views/the-game/Story.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/story', title: 'The Universe / The Story' }
                ]
            }
        },
        {
            path: 'characters',
            name: 'theuniverse-factions',
            component: () => import(/* webpackChunkName: "theuniverse-factions" */ '../views/the-game/Factions.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/characters', title: 'The Universe / The Factions' }
                ]
            }
        },
        {
            path: 'world',
            name: 'theuniverse-world',
            component: () => import(/* webpackChunkName: "theuniverse-world" */ '../views/the-game/World.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/world', title: 'The Universe / The Worlds' }
                ]
            }
        },
        {
            path: 'information',
            name: 'thegame-info',
            component: () => import(/* webpackChunkName: "thegame-info" */ '../views/the-game/Information.vue'),
            meta: {
                breadcrumbs: [
                    { href: '/the-game/information', title: 'Information' }
                ]
            }
        },
        {
            path: ':pathMatch(.*)*',
            component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        },
    ]
};
export { THEGAME_ROUTES };